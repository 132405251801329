import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Paper,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import DashButton from '../../components/DashButton';
import SelectBox from '../../components/SelectBox';
import { Context } from '../../Context';
import useOrganization from '../../hooks/useOrganization';
import { onUpdateOrganization, createNewPermission, deletePermission } from '../../services/unarmed';
import TrashIcon from '../../assets/trash.png';

const useStyles = makeStyles(() => ({
  button: {
    background: '#1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 10,
  },
  container: {
    padding: 20,
    width: '100%',
  },
  dialog: {
    minWidth: '750px',
    minHeight: '650px',
  },
  sectionTitle: {
    fontSize: 14,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  roleRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  radio: {
    color: '#A9A9A9',
    '&.Mui-checked': {
      color: '#1F63FF',
    },
  },
  headerCell: {
    position: 'relative',
    '&:hover $trashIconButton': {
      opacity: 1,
    },
  },
  trashIconButton: {
    opacity: 0,
    transition: 'opacity 0.3s ease',
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: 0,
    color: '#000',
  },
  trashIconImage: {
    width: '12px',
    height: '12px',
    filter: 'grayscale(100%)',
    opacity: 0.6,
  },
}));

export const permissionsOptionsForCases = [
  { value: 0, text: 'Disabled' },
  { value: 1, text: 'Read Only' },
  { value: 2, text: 'Write Access' },
];

const permissionsOptions = [
  { value: 0, text: 'Disabled' },
  { value: 2, text: 'Write Access' },
];

const permissionsOptionsForTwoOptions = [
  {value: 0, text: 'Disable'},
  {value: 2, text: 'Enable'}
]

const Permissions = () => {
  const classes = useStyles();
  const { organization } = useOrganization();
  const [loading, setLoading] = useState('idle');
  const { setOrganization, setActive } = useContext(Context);
  const [modules, setModules] = useState([]);
  const [rolesPermissions, setRolesPermissions] = useState({});
  const [copyRoleSetting, setCopyRoleSetting] = useState('');
  const [roleName, setRoleName] = useState('');
  const [roles, setRoles] = useState([]);
  const [createdRoles, setCreatedRoles] = useState([]);
  const [permissions, setPermissions] = useState(
    modules.reduce((acc, module) => {
      acc[module] = { disable: false, readOnly: false, write: false };
      return acc;
    }, {})
  );
  const [roleMapping, setRoleMapping] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const underlineColor = '#4285F4';
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setModalOpen(false);
    setCopyRoleSetting('');
    setRoleName('');

    setPermissions(
      modules.reduce((acc, module) => {
        acc[module] = 0;
        return acc;
      }, {})
    );

    setRolesPermissions((prev) => ({
      ...prev,
      newRole: {},
    }));
  };

  const formatRoleName = (roleName) =>
    roleName
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());

  useEffect(() => {
    setActive('/permissions');

    if (organization) {
      const normalizedPermissions = {};
      const mapping = {};

      Object.keys(organization.permissions).forEach((role) => {
        normalizedPermissions[role] = organization.permissions[role];
        mapping[formatRoleName(role)] = role;
      });
      setRolesPermissions(normalizedPermissions);
      setRoleMapping(mapping);
      setRoles(Object.keys(mapping));
      setCreatedRoles(Object.keys(normalizedPermissions));
    }
  }, [organization, setActive]);

  const handleCreateRole = () => {
    const formattedRoleName = toCamelCase(roleName);
    const fullPermissionValue = { ...defaultPermissions, ...permissions };

    const payload = {
      permissionKey: formattedRoleName,
      permissionValue: fullPermissionValue,
    };

    createNewPermission(payload)
      .then((response) => {
        toast.success(`${roleName} role created successfully!`);
        setRolesPermissions((prev) => ({
          ...prev,
          [payload.permissionKey]: payload.permissionValue,
        }));
        setCreatedRoles((prev) => [...prev, payload.permissionKey]);
        setRoles((prev) => [...prev, roleName]);
        handleCloseModal();
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || 'Failed to create role');
      });
  };

  const toCamelCase = (str) =>
    str
      .toLowerCase()
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
      .replace(/\s+/g, '');

  const defaultPermissions = {
    dashboard: 0,
    cases: 0,
    allegations: 0,
    officers: 0,
    users: 0,
    usage: 0,
    import: 0,
    activity: 0,
    data: 0,
    insights: 0,
    forms: 0,
    projects: 0,
    contacts: 0,
    formData: 0,
    permissions: 0,
    seeOnlyAssigned: 0,
    canDeleteNotesCases: 0,
    canDeleteNotesContacts: 0,
    canDeleteNotesOfficers: 0,
    canDeleteNotesProjects: 0,
    // mergeOfficers: 0,
  };

  const readOnlyModules = ['cases', 'formData'];

  const handleDeletePermission = (role) => {
    const formatRole = toCamelCase(role);

    deletePermission({ permissionKey: formatRole })
      .then(() => {
        setRolesPermissions((prev) => {
          const updatedRoles = { ...prev };
          delete updatedRoles[formatRole];
          return updatedRoles;
        });
        const indexToRemove = roles.findIndex((role1) => role1 === formatRole);
        const newRoles = [...roles];
        newRoles.splice(indexToRemove, 1);
        setRoles(newRoles);
        toast.success(`${role} permission deleted successfully!`);
      })
      .catch((error) => {
        console.error('Error deleting permission:', error);
        toast.error(error.response?.data?.message || 'Failed to delete permission');
      });
  };

  const handlePermissionChange = (module, level) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [module]: level,
    }));
  };

  const modulesWithMarginLeft = [
    'formData',
    'seeOnlyAssigned',
    'canDeleteNotesCases',
    'canDeleteNotesContacts',
    'canDeleteNotesOfficers',
    'canDeleteNotesProjects',
    // 'mergeOfficers'
  ];

  const dualPermissionsModules = [
    'seeOnlyAssigned',
    'canDeleteNotesCases',
    'canDeleteNotesContacts',
    'canDeleteNotesOfficers',
    'canDeleteNotesProjects',
    // 'mergeOfficers'
  ]

  const modulesLabels = {
    formData: 'Edit case details',
    seeOnlyAssigned: 'See Only Assigned',
    canDeleteNotesCases: 'Can Delete Notes',
    canDeleteNotesContacts: 'Can Delete Notes',
    canDeleteNotesOfficers: 'Can Delete Notes',
    canDeleteNotesProjects: 'Can Delete Notes',
    // mergeOfficers: 'Merge Officers'
  };

  useEffect(() => {
    if (organization) {
      const dashboardModules = {
        ...organization?.features?.dashboard,
        formData: { enabled: true },
        seeOnlyAssigned: { enabled: true },
        canDeleteNotesCases: { enabled: true },
        canDeleteNotesContacts: { enabled: true },
        canDeleteNotesOfficers: { enabled: true },
        canDeleteNotesProjects: { enabled: true },
        // mergeOfficers: { enabled: true }
      };
      const modulesKeys = [
        'dashboard',
        'cases',
        'formData',
        'seeOnlyAssigned',
        'canDeleteNotesCases',
        'contacts',
        'canDeleteNotesContacts',
        'allegations',
        'officers',
        'canDeleteNotesOfficers',
        // 'mergeOfficers',
        'projects',
        'canDeleteNotesProjects',
        'data',
        'insights',
        'users',
        'forms',
        'permissions',
        'import',
        'activity',
      ];

      const permissionModules = [];

      for (const key of modulesKeys) {
        if (dashboardModules[key]?.enabled) {
          permissionModules.push(key);
        }
      }

      setModules(permissionModules.slice());
    }
  }, [organization]);

  const onChangeSelectHandler = (e, role, module) => {
    const { value } = e.target;
    setRolesPermissions((prev) => ({
      ...prev,
      [role]: {
        ...prev[role],
        [module]: parseInt(value),
      },
    }));
  };

  const onSavePermissions = () => {
    const data = { permissions: rolesPermissions };
    setLoading('pending');

    onUpdateOrganization(organization?._id, data)
      .then((res) => {
        toast.success('Permissions updated successfully');
        setLoading('resolved');
        setOrganization(res.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Failed to update permissions');
        setLoading('rejected');
      });
  };

  const handleCopyRoleSettings = (selectedRole) => {
    if (selectedRole) {
      const normalizedRole = selectedRole.toLowerCase();
      const selectedRolePermissions = rolesPermissions[normalizedRole] || {};

      setPermissions(
        modules.reduce((acc, module) => {
          acc[module] = selectedRolePermissions[module] || 0;
          return acc;
        }, {})
      );
    } else {
      setPermissions(
        modules.reduce((acc, module) => {
          acc[module] = 0;
          return acc;
        }, {})
      );
    }
  };

  const minWidth = 166.705 * Object.keys(rolesPermissions).length;

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="flex-end" alignItems="center" marginBottom="15px">
        <DashButton
          variant="outlined"
          style={{
            marginRight: '10px',
            fontSize: '16px',
            borderColor: '#007bff',
            color: '#007bff',
            backgroundColor: '#ffffff',
            minWidth: '100px',
            padding: '6px 12px',
            height: '36px',
            lineHeight: 'normal',
          }}
          onClick={handleOpenModal}
        >
          New Role
        </DashButton>
        <Button variant="contained" className={classes.button} onClick={onSavePermissions}>
          {loading === 'pending' ? <CircularProgress color="#fff" size="20px" /> : 'Save'}
        </Button>
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Box display="flex">
          <Box
            sx={{
              minWidth: '200px',
              width: '200px',
              maxWidth: '200px',
              position: 'sticky',
              left: 0,
              backgroundColor: 'white',
              zIndex: 1,
              borderRight: '1px solid #e0e0e0',
              boxSizing: 'border-box',
            }}
          >
            <Box
              sx={{
                height: '56px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: '16px',
                borderBottom: '1px solid #e0e0e0',
                boxSizing: 'border-box',
              }}
            >
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  color: '#333',
                  textAlign: 'left',
                  textTransform: 'capitalize',
                  width: '100%',
                }}
              >
                Module
              </Typography>
            </Box>

            {modules.map((module, i) => (
              <Box
                key={i}
                sx={{
                  height: '56px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: modulesWithMarginLeft.includes(module) ? '32px' : '16px',
                  borderBottom: '1px solid #e0e0e0',
                  width: '200px',
                  maxWidth: '200px',
                  boxSizing: 'border-box',
                }}
              >
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'left',
                    textTransform: 'capitalize',
                    width: '200px',
                    maxWidth: '200px',
                  }}
                >
                  {module === 'formData' ? 'Edit Case Details' : modulesLabels[module] || module}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              width: '100%',
              overflowX: roles.length > 6 ? 'auto' : 'hidden',
              flexGrow: roles.length < 6 ? 1 : 'unset',
            }}
          >
            <Box display="flex" sx={{ width: '100%' }}>
              {roles.map((displayRole) => (
                <Box
                  key={displayRole}
                  className={classes.headerCell}
                  sx={{
                    height: '56px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    flexGrow: roles.length < 6 ? 1 : 'unset',
                    minWidth: '200px',
                    width: '100%',
                    borderBottom: '1px solid #e0e0e0',
                    borderRight: '1px solid #e0e0e0',
                    boxSizing: 'border-box',
                    position: 'relative',
                    paddingLeft: '14px',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      textTransform: 'capitalize',
                      textAlign: 'left',
                      width: '80%',
                    }}
                  >
                    {displayRole}
                  </Typography>
                  {displayRole !== 'Admin' && displayRole !== 'Investigator' && displayRole !== 'Guest' && (
                    <IconButton
                      className={classes.trashIconButton}
                      onClick={() => handleDeletePermission(displayRole)}
                      aria-label="delete permission"
                      disableRipple
                      sx={{ padding: 0, color: '#888' }}
                    >
                      <img src={TrashIcon} alt="Delete Icon" className={classes.trashIconImage} />
                    </IconButton>
                  )}
                </Box>
              ))}
            </Box>

            <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
              {modules.map((module, i) => (
                <Box display="flex" key={i} sx={{ minWidth }}>
                  {Object.keys(rolesPermissions).map(
                    (role) =>
                      createdRoles.includes(role) && (
                        <Box
                          key={role}
                          sx={{
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderBottom: '1px solid #e0e0e0',
                            borderRight: '1px solid #e0e0e0',
                            flexGrow: roles.length < 6 ? 1 : 'unset',
                            minWidth: '200px',
                            width: '100%',
                            boxSizing: 'border-box',
                          }}
                        >
                          <SelectBox
                            options={{
                              obj: module === 'formData'
                                ? permissionsOptionsForCases
                                : dualPermissionsModules.includes(module)
                                ? permissionsOptionsForTwoOptions
                                : permissionsOptions
                            }}
                            allowPlaceHolder={false}
                            outlined="bordeless"
                            value={rolesPermissions[role][module] || 0}
                            onChange={(e) => onChangeSelectHandler(e, role, module)}
                            sx={{
                              width: '100%',
                              textAlign: 'center',
                              '& .MuiSelect-select': {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              },
                              '& .MuiSelect-icon': {
                                right: '4px',
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  textAlign: 'center',
                                },
                              },
                            }}
                          />
                        </Box>
                      )
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Paper>

      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="new-role-dialog"
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle id="new-role-dialog" style={{ fontSize: '15px' }}>
          New Role
          <Typography variant="body2" style={{ color: 'gray', fontSize: '12px', marginTop: '1px' }}>
            Creation of new roles
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ marginTop: '-10px', paddingTop: '1px' }}>
          <Box>
            <Typography variant="subtitle1" style={{ marginBottom: '8px', fontWeight: 'bold' }}>
              Main Information
            </Typography>

            <Box display="flex" justifyContent="space-between" mb={2}>
              <TextField
                label="Role name"
                variant="standard"
                required
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
                fullWidth
                style={{ marginRight: '20px' }}
                InputLabelProps={{
                  style: { color: underlineColor, fontSize: '14px' },
                  shrink: true,
                }}
                InputProps={{
                  disableUnderline: false,
                  sx: {
                    fontSize: '12px',
                    height: '20px',
                    padding: '3px 6px',
                    '& input': {
                      fontSize: '12px',
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: underlineColor },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: underlineColor },
                    '& .MuiInput-underline:after': { borderBottomColor: underlineColor },
                  },
                }}
              />

              <FormControl variant="standard" fullWidth>
                <InputLabel style={{ color: underlineColor, fontSize: '14px' }} shrink>
                  Copy role settings
                </InputLabel>
                <Select
                  value={copyRoleSetting}
                  onChange={(e) => {
                    setCopyRoleSetting(e.target.value);
                    handleCopyRoleSettings(roleMapping[e.target.value]);
                  }}
                  label="Copy role settings"
                  disableUnderline={false}
                  sx={{
                    fontSize: '12px',
                    height: '20px',
                    padding: '3px 6px',
                    '& input': {
                      fontSize: '12px',
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: underlineColor },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: underlineColor },
                    '& .MuiInput-underline:after': { borderBottomColor: underlineColor },
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {formatRoleName(role)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Typography className={classes.sectionTitle} style={{ marginTop: 13, fontSize: '13px' }}>
              Select Role Permissions
            </Typography>
            <Paper>
              <Box display="flex" alignItems="center" p={1}>
                <Typography style={{ width: '180px', paddingLeft: '24px', fontWeight: 'bold', fontSize: '12px' }}>
                  Section
                </Typography>
                <Typography style={{ width: '160px', textAlign: 'center', fontWeight: 'bold', fontSize: '12px' }}>
                  Disable
                </Typography>
                <Typography style={{ width: '160px', textAlign: 'center', fontWeight: 'bold', fontSize: '12px' }}>
                  Read Only
                </Typography>
                <Typography style={{ width: '160px', textAlign: 'center', fontWeight: 'bold', fontSize: '12px' }}>
                  Write Access/Enable
                </Typography>
              </Box>
              <Divider />

              {modules.map((module) => (
                <Box key={module} display="flex" alignItems="center" py={0.25} px={1}>
                  <Typography
                    style={{
                      width: '180px',
                      paddingLeft: '24px',
                      textTransform: module === 'formData' ? 'none' : 'capitalize',
                      fontSize: '12px'
                    }}
                  >
                    {module === 'formData' ? 'Edit Case Details' : module.replace(/([A-Z])/g, ' $1').trim()}
                  </Typography>

                  <RadioGroup
                    row
                    value={permissions[module] || 0}
                    onChange={(e) => handlePermissionChange(module, parseInt(e.target.value))}
                    sx={{ width: '480px', justifyContent: 'space-around' }}
                  >
                    <Box display="flex" justifyContent="center" style={{ width: '160px' }}>
                      <FormControlLabel
                        value={0}
                        control={<Radio size="small" className={classes.radio} />}
                        label=""
                        style={{ margin: 0 }}
                      />
                    </Box>

                    <Box display="flex" justifyContent="center" style={{ width: '160px' }}>
                      {readOnlyModules.includes(module) ? (
                        <FormControlLabel
                          value={1}
                          control={<Radio size="small" className={classes.radio} />}
                          label=""
                          style={{ margin: 0 }}
                        />
                      ) : (
                        <Box style={{ width: '100%' }} />
                      )}
                    </Box>

                    <Box display="flex" justifyContent="center" style={{ width: '160px' }}>
                      <FormControlLabel
                        value={2}
                        control={<Radio size="small" className={classes.radio} />}
                        label=""
                        style={{ margin: 0 }}
                      />
                    </Box>
                  </RadioGroup>
                </Box>
              ))}
            </Paper>
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={1}>
            <Button
              style={{ textTransform: 'capitalize', fontSize: '10px', marginRight: '10px' }}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCreateRole}
              style={{
                padding: '4px 12px',
                fontSize: '12px',
                minWidth: '100px',
                color: underlineColor,
              }}
            >
              Create Role
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Permissions;
