/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import {
  AppBar,
  Backdrop,
  Box,
  FormControl,
  InputBase,
  makeStyles,
  Modal,
  NativeSelect,
  Tab,
  Tabs,
  Typography,
  withStyles,
  Fade,
  useMediaQuery,
  Tooltip,
  CircularProgress,
  Grid,
  Button,
} from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { toast } from 'react-toastify';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TransferIcon from '@material-ui/icons/Send';
import ArchiveIcon from '@material-ui/icons/Archive';
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';

import AssignUser from '../../components/AssignUser';
import TabPanel from '../../components/TabPanel';
import PersonalInformation from './PersonalInformation';
import IncidentDetails from './IncidentDetails';
import AditionalDetails from './AditionalDetails';
import ImageDownload from '../../components/ImageDownload';
import useTrackForm from '../../hooks/useTrackForm';
import {
  asignUserToForm,
  deleteAssignedUser,
  editFormComment,
  getActivity,
  getFormAttachments,
  getUsers,
  onUpdateFormType,
  onUpdateStatus,
  updateCaseDetails,
  updateFormComments,
} from '../../services/unarmed';
import { Context } from '../../Context';
import UpdateCase from './UpdateCase';
import CaseComment from './CaseComment';
import formatTxt from '../../utils/formatText';
import NewOfficer from './NewOfficer';
import SearchOfficer from './SearchOfficer';
import Officers from './Officers';
import UpgradeTabPopup from '../../components/UpgradeTabPopup';
import useOrganization from '../../hooks/useOrganization';
import UpgradeModal from '../../components/UpgradeModal';
import SelectBox from '../../components/SelectBox';
import TransferCase from './TransferCase';
import DuplicateCase from './DuplicateCase';
import History from './History';
import NoteLogs from '../../components/NoteLogs';
import Attachments from './Attachments';
import FormTypeJson from './FormTypeJson';
import {
  axiosCatchError,
  createFormTypePageId,
  getFormColor,
  getFormColorPlaceholder,
  transformStatus,
} from '../../utils';
import CaseTasks from './CaseTasks';
import HeaderOptions from './HeaderOptions';
import useNearScreen from '../../hooks/userNearScreen';
import { ModalEditCaseDetails } from '../../components/ModalEditCaseDetails';
import CaseContacts from './CaseContacts';
import { useAccordionProvider } from '../../provider/AccordionProvider';
import { BoxLoading } from '../../components/LoadingScreen';
import Card from '../../components/Card';
import { BoxContainer, BoxLeftContainer } from '../../styles/GlobalStyles';
import { updateUrlWithParams } from '../../utils/libs';
import { FORM_TYPE_VERSION_2 } from '../../config/config';
import RelatedCases from './RelatedCases';

const useStyles = makeStyles((theme) => ({
  container: {
  },
  headerContainer: {
    padding: '20px 20px 0',
    background: '#fff',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tag: {
    width: 'fit-content',
    marginLeft: 10,
    borderRadius: 5,
    textTransform: 'capitalize',
    height: '100%',
  },
  appBar: {
    boxShadow: 0,
    '&.MuiPaper-elevation4': {
      boxShadow: 'unset',
    },
  },
  select: {
    minWidth: 250,
    padding: 10,
    background: '#F8F8F9',
    border: 0,
    borderRadius: 5,
  },
  statusSelect: {
    background: '#008858',
    color: '#fff',
    minWidth: 'fit-content',
    padding: 10,
    border: 0,
    borderRadius: 5,
  },
  formSection: {
    fontSize: 14,
    color: '#3f51b5',
    fontWeight: 'bold',
    margin: '20px 0',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 480,
    outline: 0,
    alignSelf: 'center',
  },
  duplicatePaper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: "30px 45px",
    maxWidth: 630,
    outline: 0,
    alignSelf: 'center',
  }
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& svg': {
      color: '#fff !important',
      marginRight: 15,
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #008858',
    background: '#008858 !important',
    color: '#fff',
    paddingRight: '35px !important',
    fontSize: 16,
    minWidth: 160,
    padding: '10px 50px 10px 20px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '& option': {
      color: '#333',
    },
    '&:focus': {
      borderRadius: 4,
    },
  },
}))(InputBase);

export const archivedMessage = 'Form is archived';

const ArchivedInfo = () => (
  <Tooltip title="This case is archived" arrow>
    <Box
      display="flex"
      alignItems="center"
      style={{ fontSize: 13, color: '#c6000e', fontWeight: 'bold' }}
      marginLeft={2}
    >
      <ArchiveIcon />
      <Typography variant="body2" style={{ fontSize: 13, color: '#c6000e', fontWeight: 'bold' }}>
        Archived
      </Typography>
    </Box>
  </Tooltip>
);

const TransferredInfo = ({ form }) => {
  const next = form?.referral?.next;
  const previous = form?.referral?.previous;
  const from = previous ? `This case was transferred from ${previous.organization?.name}.` : '';
  const to = next
    ? `This case was transferred to ${next?.organization?.name} with a new tracking number: ${next?.code}.`
    : '';
  const tooltip = (
    <Typography style={{ fontSize: 11 }}>
      {from}
      {from && to ? <br /> : null}
      {to}
    </Typography>
  );

  return (
    <Tooltip title={tooltip} arrow>
      <Box
        display="flex"
        alignItems="center"
        style={{ fontSize: 13, color: '#f3b822', fontWeight: 'bold' }}
        marginLeft={2}
      >
        <TransferIcon />
        <Typography variant="body2" style={{ fontSize: 13, color: '#f3b822', fontWeight: 'bold' }}>
          Transferred
        </Typography>
      </Box>
    </Tooltip>
  );
};

const tabs = [
  { dataTestId: 'case-details-tab-case-details', label: 'Case Details' },
  { dataTestId: 'case-details-tab-case-updates', label: 'Case Updates' },
  { dataTestId: 'case-details-tab-contacts', label: 'Contacts' },
  { dataTestId: 'case-details-tab-officers', label: 'Officers' },
  { dataTestId: 'case-details-tab-tasks', label: 'Tasks' },
  { dataTestId: 'case-details-tab-notes', label: 'Notes' },
  { dataTestId: 'case-details-tab-attachments', label: 'Attachments' },
  { dataTestId: 'case-details-tab-history', label: 'History' },
];

TransferredInfo.propTypes = {
  form: PropTypes.object,
};

const CaseDetails = () => {
  const location = useLocation();
  const [value, setValue] = useState(location?.state?.tab === 'officer' ? 3 : 0);
  const { id } = useParams();
  const classes = useStyles();
  const { form, loading, refetch, setForm } = useTrackForm(id);
  const [assignedUsers, setAssignedUsers] = useState([{}]);
  const [selectedStatus, setSelectedStatus] = useState(form?.status);
  const { authUser, setActive, myUser } = useContext(Context);
  const [, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [loadingRemoveUser, setLoadingRemoveUser] = useState(false);
  const [isRequestingData, setIsRequestingData] = useState(false);
  const [formLog, setFormLog] = useState([]);
  const [loadingLog, setLoadingLog] = useState(false);
  const [officers, setOfficers] = useState([]);
  const { i18n } = useTranslation();
  const { organization } = useOrganization();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [currentFormType, setCurrentFormType] = useState('');
  const [files, setFiles] = useState([]);
  const [options, setOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [formTasks, setFormTasks] = useState([]);
  const [lastId, setLastId] = useState('');
  const [hasNextPage, setHasNextPage] = useState(true);
  const [showModalEditDetails, setShowModalEditDetails] = useState(false);
  const [formPage, setFormPage] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [caseVersionTab, setCaseVersionTab] = useState(1);
  const [editingLog, setEditingLog] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(null);
  const [customFieldsValues, setCustomFieldsValues] = useState([]);

  const { setAccordions, setAllCollapse } = useAccordionProvider();
  const formTypePages =
    caseVersionTab === 0 && form?.originalPages?.length > 0 ? form?.originalPages : form?.formType?.pages || [];
  const externalRef = useRef();
  const { isNearScreen } = useNearScreen({
    externalRef: loading === 'pending' ? null : externalRef,
    once: false,
  });
  const [loadingC, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const matches = useMediaQuery('(max-width:1440px)');
  const getForms = useCallback(
    async (withBefore = true) => {
      try {
        setLoadingLog(true);
        const query = {
          limit: 15,
          ...(form?._id ? { formId: form._id } : { formCode: id }),
          ...(withBefore ? { before: lastId } : {}),
        };

        const res = await getActivity(query);

        if (res.length === 0) {
          setLoadingLog(false);
          setHasNextPage(false);
          return;
        }

        setFormLog((items) => {
          const newItems = items.length > 0 && withBefore ? [...items, ...res] : [...res];

          setLastId(newItems[newItems.length - 1]?._id);

          return newItems;
        });
        setLoadingLog(false);
      } catch (error) {
        setLoadingLog(false);
        toast.error(error.message);
      }
    },
    [lastId, id]
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const isBlurred =
    organization?.permissions?.[myUser?.role]?.seeOnlyAssigned &&
    !assignedUsers.some((user) => user._id === myUser?._id);

  useEffect(() => {
    if (
      organization?.permissions?.[myUser?.role]?.seeOnlyAssigned &&
      !assignedUsers.some((user) => user._id === myUser?._id)
    ) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [organization, myUser, assignedUsers]);

  useEffect(() => {
    if (form?.formType && form?.formType.customFields && form?.formType.version === FORM_TYPE_VERSION_2) {
      const pages = [];

      form?.formType.customFields.forEach((page) => {
        pages.push({
          sections: [{ components: page?.sections?.[0]?.components?.map((component) => component.value) || [] }],
        });
      });

      setCustomFieldsValues(pages);
    }
  }, [form?.formType]);

  useEffect(() => {
    if (formTypePages.length > 0) {
      const firstPage = formTypePages[0];

      if (firstPage) {
        const firstPageId = createFormTypePageId(firstPage.name, 0);

        setAccordions((_accordions) => ({
          ..._accordions,
          [firstPageId]: true,
        }));
      }
    }
  }, [formTypePages, value]);

  useEffect(() => {
    if (form) {
      setFormTasks(form?.tasks || []);
    }
  }, [form]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value]);

  useEffect(() => {
    setAllCollapse();
    setActive('/cases');
  }, []);

  useEffect(() => {
    if (organization) {
      updateUrlWithParams(
        {
          organization_name: organization?.name,
          tab_name: 'case-details',
        },
        {
          tab_name: 'case-details',
        }
      );

      const uniqueTypes = new Set();

      if (organization?.caseFormTypes && organization?.formTypes) {
        organization.caseFormTypes.forEach((type) => {
          uniqueTypes.add(Array.isArray(type) ? type[1] : type);
        });

        organization.formTypes.forEach((type) => {
          uniqueTypes.add(Array.isArray(type) ? type[1] : type);
        });
      }
      const predefinedTypes = ['COMPLAINT', 'COMPLIMENT', 'RECOMMENDATION', 'REVIEW'];

      const selectOptions = Array.from(uniqueTypes).map((formType) => ({
        text: predefinedTypes.includes(formType.toUpperCase())
          ? formType.charAt(0).toUpperCase() + formType.slice(1).toLowerCase()
          : formType,
        value: predefinedTypes.includes(formType.toUpperCase())
          ? formType.charAt(0).toUpperCase() + formType.slice(1).toLowerCase()
          : formType,
      }));

      setOptions(selectOptions);
    }
  }, [organization]);

  useEffect(() => {
    if (options.length > 0 && form?.typeDisplayText) {
      const matchingOption = options.find(
        (option) => option.text === form?.typeDisplayText
      );


      if (matchingOption) {
        setCurrentFormType(matchingOption.text);
      } else {
        setOptions((prevOptions) => [
          ...prevOptions,
          { text: form?.typeDisplayText, value: form?.typeDisplayText, deleted: true },
        ]);
        setCurrentFormType(form?.typeDisplayText);
      }
    }
  }, [form?.typeDisplayText, options]);

  useEffect(() => {
    const onGetUsers = async () => {
      try {
        setLoadingUsers(true);
        const { data } = await getUsers({});
        setUsers(data);
        setLoadingUsers(false);
      } catch (error) {
        setLoadingUsers(false);
        toast.error(error.message);
      }
    };

    if (authUser) {
      onGetUsers();
    }
  }, [authUser]);

  useEffect(() => {
    if (form?.assigned?.length > 0) {
      setAssignedUsers([...form?.assigned, {}]);
    }

    if (form?.status) {
      setSelectedStatus(form?.status);
      setCurrentFormType(form?.typeDisplayText);
    }

    if (form?.officers) {
      setOfficers(form?.officers);
    }
  }, [form]);

  useEffect(() => {
    const onGetFormAttachments = async () => {
      getFormAttachments(form?._id)
        .then(({ data }) => {
          const caseAttachments = data.filter((attachment) => attachment.source === 'CASE_DETAILS');
          setFiles(caseAttachments);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    };
    if (form?._id) {
      getForms();
      onGetFormAttachments();
    }
  }, [form?._id, authUser, isRequestingData, form, id]);

  const onChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    console.log('Options Array:', options);
  }, [options]);

  const updateFormType = async (e) => {
    const { value: selectValue } = e.target;

    if (selectValue === 'REFERRAL') {
      setShowModal(true);
      return;
    }

    const normalizedType = selectValue.replace(/ /g, '_').toUpperCase();

    try {
      const newOptionSelected = options.find((option) => option.value === selectValue);

      if (newOptionSelected && newOptionSelected?.deleted) {
        toast.error("This case type has been removed and can no longer be used.");
        return;
      }

      const { data } = await onUpdateFormType(form?._id, normalizedType);
      setForm((prevState) => ({ ...prevState, ...data }));
      setCurrentFormType(selectValue);
      toast.success('Form Type has been updated successfully!');
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };


  const onSetupFiles = () => {};

  const onDeleteUser = async (user, index) => {
    const usersArr = [...assignedUsers];
    const removeuser = usersArr.splice(index, 1);
    setAssignedUsers([...usersArr]);
    const userData = await {
      unassignUsers: [removeuser[0]._id],
    };
    try {
      setIsRequestingData(true);
      setLoadingRemoveUser(true);
      await deleteAssignedUser(authUser.tokenId, form?._id, userData);
      toast.success(`${removeuser[0].email} was successfully unassigned from the case`);
      setLoadingRemoveUser(false);
      setIsRequestingData(false);
    } catch (error) {
      if (form?.archived) {
        toast.error(archivedMessage);
      }
      axiosCatchError(error);
      setLoadingRemoveUser(false);
    }
  };

  useEffect(() => {
    if (options.length > 0 && form?.typeDisplayText) {
      const matchingOption = options.find(
        (option) => option.text.toLowerCase() === form?.typeDisplayText.toLowerCase()
      );

      if (matchingOption) {
        setCurrentFormType(matchingOption.text);
      }
    }
  }, [form?.typeDisplayText, options]);


  const onAssignUser = async (usersArr, callback) => {
    const asignedUsersData = {
      assignUsers: usersArr?.map((user) => user?._id),
    };

    try {
      setIsRequestingData(true);
      await asignUserToForm(authUser.tokenId, form?._id, asignedUsersData);

      const lastAssignedUser = usersArr[usersArr.length - 1];
      toast.success(`${lastAssignedUser?.email} was successfully assigned to the case`);
      callback();
      setIsRequestingData(false);
    } catch (error) {
      if (form?.archived) {
        toast.error(archivedMessage);
      }
      axiosCatchError(error);
    }
  };

  const onChangeStatus = async (e) => {
    e.persist();

    const _value = e.target.value;

    try {
      setIsRequestingData(true);
      const { data } = await onUpdateStatus(authUser.tokenId, form?._id, {
        status: _value,
      });

      toast.success(
        `${authUser?.email} has updated the status to ${transformStatus(
          form?.availableStatuses?.filter((st) => st.status === _value)[0].status
        )} Successfully`
      );
      setForm((prevState) => ({ ...prevState, ...data }));
      setSelectedStatus(_value);
      setIsRequestingData(false);
    } catch (error) {
      setIsRequestingData(false);
      toast.error(error.response?.data?.message);
    }
  };

  const handleChange = (event, newValue) => {
    const tabData = tabs[newValue];
    const tabLabel = tabData.label?.toLowerCase()?.replace(/ /g, '-');

    setValue(newValue);
    updateUrlWithParams(
      {
        organization_name: organization.name,
        tab_name: tabLabel,
      },
      {
        tab_name: tabLabel,
      }
    );

    if (form?._id && newValue === 7) {
      setLastId('');
      getForms(false);
    }
  };

  const handleOnEditCaseDetails = (formValues) => {
    updateCaseDetails(form?._id, {
      pageIndex,
      page: formValues[pageIndex],
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            form?.originalPages?.length === 0
              ? 'Information has been changed and new version history has created.'
              : 'Information has been changed.'
          );

          refetch();

          setShowModalEditDetails(false);
        }
      })
      .catch((error) => {
        const errorResponse =
          error.response?.data?.error?.details || error.response?.data?.error?.error?.details || null;

        if (Array.isArray(errorResponse) && errorResponse?.length > 0) {
          return errorResponse.forEach((res) => {
            if (res.message === '"value" must be a string') {
              const section = formPage?.sections[error?.response?.data?.iSection];
              const component = section?.components[error?.response?.data?.iComponent];
              const label = component?.data?.question || 'Field';

              return toast.error(`${label} must not be empty`);
            }

            toast.error(res.message || 'Something went wrong creating the form');
          });
        }

        return toast.error('Something went wrong creating the form');
      });
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box className={`container ${isBlurred ? 'blur' : ''}`}>
        {!loadingC && (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={isModalOpen}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={isModalOpen}>
              <div className={classes.paper}>
                <Typography variant="h6" id="transition-modal-title" align="center">
                  You do not have permission to view this case.
                </Typography>
                <Box display="flex" justifyContent="center" marginTop={2}>
                  <Button
                    onClick={handleCloseModal}
                    style={{ color: '#1976d2', fontSize: '20px', textTransform: 'none' }}
                  >
                    Close
                  </Button>
                </Box>
              </div>
            </Fade>
          </Modal>
        )}
        <UpgradeModal open={showUpgradeModal} callback={() => setShowUpgradeModal(false)} />
        <Box display="flex" justifyContent="space-between" className={classes.headerContainer}>
          <Box>
            <Box>
              <Box display="flex">
                <Typography variant="h6">Tracking Number: {form?.code}</Typography>
                {currentFormType === 'REFERRAL' && !matches && (
                  <Box display="flex" alignItems="center">
                    <Box
                      marginLeft="20px"
                      marginRight="10px"
                      padding="7px 12px"
                      borderRadius="4px"
                      width="fit-content"
                      color="#2E66FE"
                      fontWeight="500"
                      style={{ background: '#2E66FE1A' }}
                    >
                      Referral
                    </Box>
                  </Box>
                )}
                {currentFormType !== 'REFERRAL' && (
                  <Box
                    className={classes.tag}
                    style={{
                      background: getFormColorPlaceholder(currentFormType),
                    }}
                  >
                    <SelectBox
                      options={{ obj: options }}
                      value={currentFormType}
                      onChange={(e) => updateFormType(e)}
                      allowPlaceHolder={false}
                      withColors={{
                        color: getFormColor(currentFormType),
                      }}
                    />
                  </Box>
                )}
                {form?.archived && <ArchivedInfo form={form} />}
                {form?.transferred && <TransferredInfo form={form} />}
              </Box>
              <Typography variant="body2" style={{ color: '#585858', fontSize: 13, marginBottom: 20 }}>
                Created by{' '}
                {form?.civilian?.firstName
                  ? ` ${formatTxt(form?.civilian?.firstName, 15)} ${formatTxt(form?.civilian?.lastName, 15)} -`
                  : 'Not specified -'}{' '}
                {moment(form?.createdAt).format('MMMM DD, YYYY')}
              </Typography>
              {currentFormType === 'REFERRAL' && matches && (
                <Box display="flex" alignItems="center" marginBottom="20px">
                  <Box
                    marginRight="10px"
                    padding="7px 12px"
                    borderRadius="4px"
                    width="fit-content"
                    color="#2E66FE"
                    fontWeight="500"
                    style={{ background: '#2E66FE1A' }}
                  >
                    Referral
                  </Box>
                  <Typography variant="body2" style={{ color: '#585858', fontSize: 13 }}>
                    This case was transferred to {form?.referral?.next?.organization?.name} with a new tracking number:{' '}
                    {form?.referral?.next?.code}
                  </Typography>
                </Box>
              )}
            </Box>
            <AppBar position="static" color="transparent" className={classes.appBar}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
              >
                {tabs.map((tab, tabKey) => (
                  <TabComp data-testid={tab.dataTestId} label={tab.label} {...a11yProps(tabKey)} />
                ))}
              </Tabs>
            </AppBar>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <AssignUser
                assignedUsers={assignedUsers}
                form={form}
                defaultUsers={users}
                setAssignedUsers={setAssignedUsers}
                onAssignUser={onAssignUser}
                loadingRemoveUser={loadingRemoveUser}
                onDeleteUser={onDeleteUser}
              />
            </Box>

            <Box style={{ marginLeft: 20 }}>
              <Box display="flex" justifyContent="flex-end">
                <FormControl
                  onClick={() => {
                    if (!organization?.features?.dashboard?.cases?.status) {
                      setShowUpgradeModal(true);
                    }
                  }}
                >
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={selectedStatus}
                    onChange={onChangeStatus}
                    input={<BootstrapInput />}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    {form?.availableStatuses?.map((avalaible, i) => (
                      <option
                        key={i}
                        value={avalaible.status}
                        style={{
                          textAlign: 'center',
                          marginRight: 10,
                        }}
                      >
                        {transformStatus(avalaible.status)?.toUpperCase()}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
                {myUser?.role === 'admin' && (
                  <HeaderOptions
                    formId={form?._id}
                    archived={form?.archived}
                    transferredTo={(form?.transferred && form.referral.next) || form?.type === 'REFERRAL'}
                    setForm={setForm}
                    onTransferClick={() => setShowModal(true)}
                    onDuplicateClick={() => setShowDuplicateModal(true)}

                  />
                )}
              </Box>
              <Typography variant="body2" style={{ color: '#585858', fontSize: 13, marginTop: 10 }}>
                Last update {moment(form?.updatedAt).format('MMMM DD, YYYY - hh:mm a')}
              </Typography>
              <Box display="flex" style={{ marginTop: 25 }} justifyContent="flex-end">
                {/* <a href={`/case-exported/${id}`} target="_blank noreferrer">
                <ArchiveIcon
                  fontSize="medium"
                  htmlColor="#6D7385"
                  style={{ marginRight: 20, cursor: 'pointer' }}
                />
              </a> */}

                {/* <MoreHorizIcon fontSize="medium" htmlColor="#6D7385" /> */}
              </Box>
            </Box>
          </Box>
        </Box>

        <TabPanel padding={'20px 0px'} value={value} index={0}>
          {loading ? (
            <div>Loading</div>
          ) : (
            <Box display="flex" justifyContent="space-between" width="100%" height="100%">
              <Box marginRight="20px" width="100%">
                {form?.originalPages?.length > 0 && (
                  <Box style={{ marginBottom: 16, background: '#ffffff', padding: 20, borderRadius: 5 }}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="h6">Version</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end">
                          <Tabs
                            value={caseVersionTab}
                            onChange={(e, i) => setCaseVersionTab(i)}
                            indicatorColor="primary"
                            textColor="primary"
                          >
                            <TabComp label="Original" {...a11yProps(0)} />
                            <TabComp label="Edited" {...a11yProps(1)} />
                          </Tabs>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {form?.formType ? (
                  <FormTypeJson
                    formType={{
                      pages: formTypePages,
                    }}
                    external={form?.external}
                    onSetupFiles={onSetupFiles}
                    onClickEditPage={
                      (caseVersionTab === 0 ? form?.originalPages?.length === 0 : true) &&
                      organization?.permissions?.[myUser?.role]?.formData
                        ? (page, _pageIndex) => {
                            setShowModalEditDetails(true);
                            setFormPage(page);
                            setPageIndex(_pageIndex);
                          }
                        : undefined
                    }
                  />
                ) : (
                  <>
                    <PersonalInformation form={form} />
                    <IncidentDetails form={form} setForm={setForm} />
                    <AditionalDetails form={form} />
                  </>
                )}
              </Box>
              <BoxContainer>
                <Card title="Case Form">
                  <ImageDownload
                    id={id}
                    version="original"
                    extension="PDF"
                    name="Original"
                    onClick={() => {
                      onChangeLanguage('en');
                    }}
                  />
                  {form?.originalPages?.length > 0 && (
                    <ImageDownload
                      id={id}
                      version="edited"
                      extension="PDF"
                      name="Edited"
                      onClick={() => {
                        onChangeLanguage('en');
                      }}
                    />
                  )}
                </Card>
                <Card title="Attached Files">
                  {files.length > 0 ? (
                    <>
                      {files?.map((file, i) => (
                        <ImageDownload
                          index={i}
                          key={i}
                          size={file.size}
                          extension={file.name.match(/[^.]+$/)}
                          name={file.name}
                          urlImg={file.download_url}
                        />
                      ))}
                    </>
                  ) : (
                    <Typography style={{ textAlign: 'center', color: '#9E9E9E', fontWeight: '500' }}>
                      No files attached
                    </Typography>
                  )}
                </Card>
                <RelatedCases formId={form?._id} related={form?.related || []} />
              </BoxContainer>
            </Box>
          )}
        </TabPanel>
        <TabPanel padding={'20px 0px'} value={value} index={1}>
          {!organization?.features?.dashboard?.cases?.caseUpdates && <UpgradeTabPopup />}
          <Box
            justifyContent="space-between"
            height="100%"
            width="100%"
            style={
              !organization?.features?.dashboard?.cases?.caseUpdates
                ? {
                    filter: 'blur(2px)',
                    height: '70vh',
                    overflow: 'hidden',
                  }
                : {}
            }
          >
            <UpdateCase
              token={authUser?.tokenId}
              id={form?._id}
              status={selectedStatus}
              setIsRequestingData={setIsRequestingData}
              form={form}
              setForm={setForm}
              formType={form?.formType}
              customFieldsValues={customFieldsValues}
              setCustomFieldsValues={setCustomFieldsValues}
            />
          </Box>
        </TabPanel>
        <TabPanel padding={'20px 0px'} value={value} index={2}>
          {(!organization?.permissions?.[myUser?.role]?.contacts ||
            !organization?.features?.dashboard?.contacts.enabled) && <UpgradeTabPopup />}
          <CaseContacts
            defaultContacts={form?.contacts || []}
            caseId={form?._id}
            onAddContact={refetch}
            onRemoveContact={refetch}
          />
        </TabPanel>
        <TabPanel padding={'20px 0px'} value={value} index={3}>
          {!organization ? (
            <BoxLoading />
          ) : (
            <>
              {(!organization?.permissions?.[myUser?.role]?.officers ||
                !organization?.features?.dashboard?.cases?.officersTab ||
                !organization?.features?.dashboard?.officers.enabled) && <UpgradeTabPopup />}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                height="100%"
                style={
                  !organization?.features?.dashboard?.cases?.officersTab
                    ? {
                        filter: 'blur(2px)',
                        height: '70vh',
                        overflow: 'hidden',
                      }
                    : {}
                }
              >
                <Officers
                  officersAllegationOptions={form?.formType?.officersAllegationOptions}
                  setForm={setForm}
                  form={form}
                  formType={form?.formType}
                  loading={loading}
                  officers={officers}
                  id={form?._id}
                  officersOption={form?.formType?.officersAllegationOptions}
                  setIsRefetch={() => refetch()}
                  setIsRequestingData={setIsRequestingData}
                />
                <BoxContainer marginLeft={2}>
                  <SearchOfficer
                    id={form?._id}
                    defaultOfficers={officers}
                    setIsRefetch={() => refetch()}
                    setIsRequestingData={setIsRequestingData}
                  />
                  <NewOfficer
                    id={form?._id}
                    setIsRefetch={() => refetch()}
                    setIsRequestingData={setIsRequestingData}
                    caseArchived={form?.archived}
                  />
                </BoxContainer>
              </Box>
            </>
          )}
        </TabPanel>
        <TabPanel padding={'20px 0px'} value={value} index={4}>
          <CaseTasks users={users} form={form} tasks={formTasks} updateTasks={setFormTasks} />
        </TabPanel>
        <TabPanel padding={'20px 0px'} value={value} index={5}>
          {!organization?.features?.dashboard?.cases?.notes && <UpgradeTabPopup />}
          <Box
            style={
              !organization?.features?.dashboard?.cases?.notes
                ? {
                    filter: 'blur(2px)',
                    height: '70vh',
                    overflow: 'hidden',
                  }
                : {}
            }
            display="flex"
          >
            <NoteLogs
              canEdit
              canDelete
              id={form?._id}
              isRequestingData={isRequestingData}
              onEditClick={(item) => setEditingLog(item)}
              onDeleteClick={(item) => setOpenDeleteModal(item)}
              onCancelEditingClick={() => setEditingLog(null)}
              editingLog={editingLog}
            />
            <CaseComment
              organizationId={organization?._id}
              setIsRequestingData={setIsRequestingData}
              callBack={async (filesHash, comment, resetValues, canceled = false) => {
                if (!canceled) {
                  if (editingLog) {
                    await editFormComment(form?._id, editingLog._id, comment);
                  } else {
                    await updateFormComments(form?._id, 'notToken', filesHash, comment);
                  }
                }

                refetch();
                setEditingLog(null);
                resetValues();
              }}
              editingLog={editingLog}
            />
          </Box>
        </TabPanel>
        <TabPanel padding={'20px 0px'} value={value} index={6}>
          {!organization?.features?.dashboard?.cases?.attachments && <UpgradeTabPopup />}
          <Box
            style={
              !organization?.features?.dashboard?.cases?.attachments
                ? {
                    filter: 'blur(2px)',
                    height: '70vh',
                    overflow: 'hidden',
                  }
                : {}
            }
            display="flex"
          >
            <Attachments
              id={form?._id}
              trackingNumber={id}
              setIsRequestingData={setIsRequestingData}
              onEditAttachments={() => refetch()}
            />
          </Box>
        </TabPanel>
        <TabPanel padding={'20px 0px'} value={value} index={7}>
          <BoxLeftContainer>
            <Card title="History">
              <History log={formLog} loading={loadingLog} />
            </Card>
          </BoxLeftContainer>
          <div id="bottom" ref={externalRef} />
          {isNearScreen && hasNextPage && lastId && (
            <Waypoint onEnter={getForms}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                maxWidth="800px"
                style={{ marginTop: 24 }}
              >
                <CircularProgress color="#333" size="30px" style={{ marginLeft: 10 }} />
              </Box>
            </Waypoint>
          )}
        </TabPanel>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={showModal}
          onClose={() => setShowModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModal}>
            <div className={classes.paper}>
              <TransferCase
                organizationName={`Siviltech - ${organization?.name}`}
                setOpen={setShowModal}
                id={form?._id}
                setForm={setForm}
                setIsRequestingData={setIsRequestingData}
              />
            </div>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={showDuplicateModal}
          onClose={() => setShowDuplicateModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showDuplicateModal}>
            <div className={classes.duplicatePaper}>
              <DuplicateCase
                organizationName={`Siviltech - ${organization?.name}`}
                setOpen={setShowDuplicateModal}
                typeOptions={options}
                id={form?._id}
                setForm={setForm}
                setIsRequestingData={setIsRequestingData}
              />
            </div>
          </Fade>
        </Modal>
        {formPage && form && (
          <ModalEditCaseDetails
            open={showModalEditDetails}
            pageIndex={pageIndex}
            page={formPage}
            formPages={form?.formType?.pages}
            formId={form?.formType?._id}
            organization={organization}
            onClose={() => setShowModalEditDetails(false)}
            title={`Edit ${formPage.name}`}
            onSubmit={handleOnEditCaseDetails}
          />
        )}
      </Box>
    </>
  );
};

export const TabComp = styled(Tab)`
  text-transform: capitalize !important;
  width: fit-content !important;
  font-size: 16px !important;

  .MuiTab-root {
    min-width: unset !important;
  }
`;

export default CaseDetails;
