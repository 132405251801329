/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-prototype-builtins */
import { Box, Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MultiSelectBox from '../../components/MultiSelectBox';
import useInput from '../../hooks/useInput';
import { updateForm } from '../../services/unarmed';
import { subtypeOptions } from '../../utils/options';
import UpdateRenderer from './UpdateCaseComponents/UpdateRenderer';
import CaseUpdateSection from '../../components/CaseUpdateSection';
import { FORM_TYPE_VERSION_2 } from '../../config/config';
import Card from '../../components/Card';
import { useAccordionProvider } from '../../provider/AccordionProvider';
import { BoxContainer } from '../../styles/GlobalStyles';

export const sourceOptions = ['Online', 'In-Person', 'Email', 'Phone', 'Other'];
export const ReporterOptions = ['Civilian', 'Civilian Oversight', 'Internal Affairs', 'Other'];
export const AllegationOptions = [
  'Use of Force',
  'Abuse of Authority',
  'Discourtesy',
  'Offensive Language',
  'Lack of Service',
];
export const DispositionOptions = ['Sustained', 'Not Sustained', 'Unfounded', 'Improper Conduct', 'Policy Failure'];

export const DisciplineOptions = [
  'Counseled',
  'Letter of Reprimand',
  'Suspension',
  'Demotion',
  'Dismissal',
  'Resignation',
  'No Further Action',
  'Other',
];

const useStyles = makeStyles(() => ({
  formSection: {
    fontSize: 14,
    color: '#3f51b5',
    fontWeight: '500',
    margin: '20px 0',
  },
  containerTitle: {
    padding: '10px 20px',
    borderBottom: '1px solid #e2e2e2',
  },
  label: {
    fontWeight: '500',
    fontSize: 14,
    margin: '10px 0 10px',
  },
  button: {
    background: ' #4b7bff',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
  },
  labelError: {
    fontSize: 12,
    color: 'red',
  },
}));

const UpdateCase = ({
  id,
  status,
  setIsRequestingData,
  form,
  setForm,
  formType,
  customFieldsValues,
  setCustomFieldsValues,
}) => {
  const classes = useStyles();
  const internalAffairs = useInput(form?.internalAffairsCaseNumber || '');
  const caseNumber = useInput(
    formType?.caseUpdates?.caseNumber?.hasOwnProperty('automate') ? form?.caseNumber : form?.caseNumber || form?.code
  );
  const source = useInput(form?.source || []);
  const subType = useInput(form?.subtype || []);
  const reporter = useInput(form?.reporter || []);
  const investigator = useInput(form?.investigator || []);
  const [allegation, setAllegation] = useState(form?.allegations || []);
  const [disposition, setDisposition] = useState(form?.dispositions || []);
  const [discipline, setDiscipline] = useState(form?.discipline || []);
  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(null);
  // const [editStandard, setEditStandard] = useState(false);
  const [contents, setContents] = useState([]);
  const { accordions, setAccordions } = useAccordionProvider();

  // Check if any of the caseUpdates fields are visible
  const checkStandardVisible = () => {
    const keys = Object.keys(formType?.caseUpdates);

    for (const key of keys) {
      if (formType?.caseUpdates?.[key]?.visible) {
        return true;
      }
    }

    return false;
  };

  // Check if any of the caseUpdates fields are visible excluding caseNumber and internallAffairs
  const checkCaseUpdatesVisible = () => {
    const keys = Object.keys(formType?.caseUpdates);

    for (const key of keys) {
      // if (['caseNumber', 'internalAffairs'].includes(key)) {
      //   continue;
      // }

      if (formType?.caseUpdates?.[key]?.visible) {
        return true;
      }
    }

    return false;
  };

  const standardVisible = useMemo(() => checkStandardVisible(), [formType]);
  const standardCaseUpdatesVisible = useMemo(() => checkCaseUpdatesVisible(), [formType]);

  useEffect(() => {
    if (formType && formType.version === FORM_TYPE_VERSION_2) {
      const newContents = formType?.customFields.map((page, pageIndex) => {
        const pageName = page?.name?.toLowerCase().replace(/ /g, '-');
        const itemId = `custom-section-${pageIndex}-${pageName}-${id}`;

        return {
          active: page.active,
          name: page.name,
          value: itemId,
          components: page?.sections[0]?.components?.length || 0,
        };
      });

      if (standardVisible) {
        const allContents = [
          {
            name: 'Standard',
            value: 'standard',
            active: form?.formType?.caseUpdates?.active
          },
          ...newContents,
        ];
        setContents(allContents);

        const initialAccordions = allContents.reduce((acc, content) => ({
          ...acc,
          [content.value]: content.value === 'standard',
        }), {});

        setAccordions(initialAccordions);
      } else if (newContents.length > 0) {
        setContents(newContents);

        const initialAccordions = newContents.reduce((acc, content) => ({
          ...acc,
          [content.value]: content.value === newContents[0].value,
        }), {});

        setAccordions(initialAccordions);
      }
    }
  }, []);

  useEffect(() => {
    console.log({accordions, contents});
  }, [accordions]);

  // eslint-disable-next-line no-shadow
  const onSaveFormUpdate = async ({ requestData, setLoadingState }) => {
    try {
      setLoading(true);
      setLoadingState();
      console.log('AQUI: onSaveFormUpdate', requestData);
      const { data } = await updateForm(id, requestData);
      toast.success('The form has been updated successfully');
      setForm({
        ...form,
        caseNumber: data.caseNumber,
        source: data.source,
        reporter: data.reporter,
        allegations: data.allegations,
        discipline: data.discipline,
        dispositions: data.dispositions,
        investigator: data.investigator,
        internalAffairsCaseNumber: data.internalAffairsCaseNumber,
        subtype: data.subtype,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.error?.details[0]?.message);
    } finally {
      setLoading(false);
      setLoadingState('');
      setIsRequestingData(false);
    }
  };
  const onUpdateForm = async (e) => {
    e.preventDefault();

    const requestData = {
      status,
      caseNumber: caseNumber.value,
      customFields: customFieldsValues,
      ...(internalAffairs.value !== '' ? { internalAffairsCaseNumber: internalAffairs.value } : {}),
      ...(formType?.caseUpdates?.source?.visible && source.value && source.value !== '0' && { source: source.value }),
      ...(formType?.caseUpdates?.subtype?.visible &&
        subType.value &&
        subType.value !== '0' && { subtype: subType.value }),
      ...(formType?.caseUpdates?.reporter?.visible &&
        reporter.value &&
        reporter.value !== '0' && { reporter: reporter.value }),
      ...(formType?.caseUpdates?.investigator?.visible &&
        investigator.value &&
        investigator.value !== '0' && { investigator: investigator.value }),
      ...(formType?.caseUpdates?.allegations?.visible && allegation?.length && { allegations: allegation }),
      ...(formType?.caseUpdates?.dispositions?.visible && disposition?.length && { dispositions: disposition }),
      ...(formType?.caseUpdates?.disciplines?.visible && discipline?.length && { discipline }),
    };
    onSaveFormUpdate({
      requestData,
      setLoadingState: () => setLoadingState('updateForm'),
    });
  };

  return (
    <Box display="flex" justifyContent="space-between" width="100%" height="100%">
      <Box width="100%">
        {(standardVisible && (formType?.caseUpdates?.active || formType?.caseUpdates?.active === undefined) )&& (
          <Box style={{ background: '#fff', borderRadius: 6 }} boxShadow="0 3px 6px #00000005" marginBottom="24px">
            <CaseUpdateSection
              id="standard"
              dataTestId="case-updates-accordion-standard"
              name="Standard"
              edit
              data={[
                {
                  name: 'Case number',
                  value: caseNumber.value,
                },
                {
                  name: 'Internal Affairs Case Number',
                  value: internalAffairs.value,
                },
                {
                  name: 'Subtype',
                  value: subType.value,
                },
                {
                  name: 'Source',
                  value: source.value,
                },
                {
                  name: 'Reporter',
                  value: reporter.value,
                },
                {
                  name: 'Investigator',
                  value: investigator.value,
                },
                {
                  name: 'Allegation',
                  value: allegation.join(', '),
                },
                {
                  name: 'Disposition',
                  value: disposition.join(', '),
                },
                {
                  name: 'Discipline',
                  value: discipline.join(', '),
                },
              ]}
            >
              <Box display="flex" width="100%" flexDirection="column">
                <Box width="100%" maxWidth={600}>
                  {formType?.caseUpdates?.caseNumber?.visible && (
                    <Box
                      style={{ background: '#fff', borderRadius: 5 }}
                      boxShadow="0 3px 6px #00000005"
                      marginBottom="24px"
                    >
                      <Typography variant="body2" className={classes.label}>
                        Case Number
                      </Typography>
                      <OutLineInput
                        placeholder="Enter your case number"
                        value={caseNumber.value}
                        onChange={caseNumber.onChange}
                        error={caseNumber.error}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'weight',
                        }}
                        labelWidth={0}
                        disabled={
                          formType?.caseUpdates?.caseNumber?.hasOwnProperty('automate')
                            ? formType?.caseUpdates?.caseNumber?.automate?.enabled &&
                              !formType?.caseUpdates?.caseNumber?.automate?.manualOverride
                            : false
                        }
                      />
                      <span className={classes.labelError}>{caseNumber.error}</span>
                    </Box>
                  )}
                  {formType?.caseUpdates?.internalAffairs?.visible && (
                    <Box
                      style={{ background: '#fff', borderRadius: 5 }}
                      boxShadow="0 3px 6px #00000005"
                      width="100%"
                      maxWidth={600}
                    >
                      <Typography variant="body2" className={classes.label}>
                        Internal Affairs Case Number
                      </Typography>
                      <OutLineInput
                        placeholder="Enter an internal affairs case number"
                        value={internalAffairs.value}
                        onChange={internalAffairs.onChange}
                        error={internalAffairs.error}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'weight',
                        }}
                        labelWidth={0}
                      />
                      <span className={classes.labelError}>{internalAffairs.error}</span>
                    </Box>
                  )}
                </Box>
                {standardCaseUpdatesVisible && (
                  <Box
                    style={{ background: '#fff', borderRadius: 5 }}
                    boxShadow="0 3px 6px #00000005"
                    width="100%"
                    maxWidth={600}
                    height="fit-content"
                  >
                    <Box>
                      <form noValidate autoComplete="off" className={classes.form} onSubmit={onUpdateForm}>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                          {formType?.caseUpdates?.subtype?.visible && (
                            <Grid item xs={12}>
                              <Typography variant="body2" className={classes.label}>
                                Subtype
                              </Typography>
                              <MultiSelectBox
                                defaultValue={false}
                                value={subType.value}
                                onChange={subType.onChange}
                                id="subtypeSelectBox"
                                options={formType?.caseUpdates?.subtype?.values || subtypeOptions}
                              />
                            </Grid>
                          )}
                          {formType?.caseUpdates?.source?.visible && (
                            <Grid item xs={12}>
                              <Typography variant="body2" className={classes.label}>
                                Source
                              </Typography>
                              <MultiSelectBox
                                defaultValue={false}
                                value={source.value}
                                onChange={source.onChange}
                                id="sourceMultiSelectBox"
                                options={formType?.caseUpdates?.source?.values || sourceOptions}
                              />
                            </Grid>
                          )}
                          {formType?.caseUpdates?.reporter?.visible && (
                            <Grid item xs={12}>
                              <Typography variant="body2" className={classes.label}>
                                Reporter
                              </Typography>
                              <MultiSelectBox
                                defaultValue={false}
                                value={reporter.value}
                                onChange={reporter.onChange}
                                id="reportMultiSelectBox"
                                options={formType?.caseUpdates?.reporter?.values || ReporterOptions}
                              />
                            </Grid>
                          )}
                          {formType?.caseUpdates?.investigator?.visible && (
                            <Grid item xs={12}>
                              <Typography variant="body2" className={classes.label}>
                                Investigator
                              </Typography>
                              <MultiSelectBox
                                defaultValue={false}
                                value={investigator.value}
                                onChange={investigator.onChange}
                                id="investigatorMultiSelectBox"
                                options={formType?.caseUpdates?.investigator?.values || []}
                              />
                            </Grid>
                          )}
                          {formType?.caseUpdates?.allegations?.visible && (
                            <Grid item xs={12}>
                              <Typography variant="body2" className={classes.label}>
                                Allegation
                              </Typography>
                              <MultiSelectBox
                                defaultValue={false}
                                value={allegation}
                                onChange={(e) => setAllegation(e.target.value)}
                                id="alegationMutliSelct"
                                options={formType?.caseUpdates?.allegations?.values || AllegationOptions}
                              />
                            </Grid>
                          )}
                          {formType?.caseUpdates?.dispositions?.visible && (
                            <Grid item xs={12}>
                              <Typography variant="body2" className={classes.label}>
                                Disposition
                              </Typography>
                              <MultiSelectBox
                                defaultValue={false}
                                value={disposition}
                                onChange={(e) => setDisposition(e.target.value)}
                                id="alegationMutliSelct"
                                options={formType?.caseUpdates?.dispositions?.values || DispositionOptions}
                              />
                            </Grid>
                          )}
                          {formType?.caseUpdates?.disciplines?.visible && (
                            <Grid item xs={12}>
                              <Typography variant="body2" className={classes.label}>
                                Discipline
                              </Typography>
                              <MultiSelectBox
                                value={discipline}
                                onChange={(e) => setDiscipline(e.target.value)}
                                id="alegationMutliSelct"
                                defaultValue={false}
                                options={formType?.caseUpdates?.disciplines?.values || DisciplineOptions}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </form>
                    </Box>
                  </Box>
                )}
              </Box>
            </CaseUpdateSection>
          </Box>
        )}
        <UpdateRenderer
          id={id}
          setForm={setForm}
          version={formType?.version}
          components={formType?.customFields}
          customFieldsValues={customFieldsValues}
          setCustomFieldsValues={setCustomFieldsValues}
          setIsRequestingData={setIsRequestingData}
        />
      </Box>
      <BoxContainer marginLeft={2}>
        <Card
          title="Contents"
          btn={
            <Button
              size="small"
              variant="contained"
              color="primary"
              type="submbit"
              disabled={loading && loadingState === 'updateForm'}
              onClick={onUpdateForm}
            >
              {loading && loadingState === 'updateForm' ? <CircularProgress color="#fff" size="25px" /> : 'Save'}
            </Button>
          }
        >
          <StyledList>
            {contents.map((content, contentKey) => {
              if (content?.components === 0) return null;

              if (!content.active) return null;

              return (
                <ContentText
                  onClick={() => {
                    setAccordions((prevAccordions) => ({
                      ...prevAccordions,  // Preserve existing accordion states
                      [content.value]: !prevAccordions[content.value],
                    }));
                  }}
                  key={contentKey}
                  active={accordions[content.value]}
                >
                  {content.name}
                </ContentText>
              );
            })}
          </StyledList>
        </Card>
      </BoxContainer>
    </Box>
  );
};

const StyledList = styled.ul`
  list-style: inside;
`;

export const OutLineInput = styled('input')`
  background-color: #f8f8f9;
  height: 40px;
  font-size: 14px !important;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  outline: 0;
  &::placeholder {
    color: #00000061;
  }
  ${(props) =>
    props.bordered &&
    `
    border: 1px solid ${props.active ? '#408BEC' : 'rgba(0, 0, 0, 0.23)'};
    background: transparent;
  `}
  ${(props) =>
    props.error &&
    `
    border: 1px solid red !important;
  `}
`;

const ContentText = styled.li`
  font-size: 16px;
  color: #575a66;
  font-weight: 500;
  margin-bottom: 8px;
  cursor: pointer;

  ${(props) =>
    props.active &&
    `
    color: #2E66FE;
  `}
`;

UpdateCase.propTypes = {
  id: PropTypes.string,
  status: PropTypes.bool,
  setIsRequestingData: PropTypes.func,
  form: PropTypes.object,
  setForm: PropTypes.func,
  formType: PropTypes.object,
  customFieldsValues: PropTypes.array,
  setCustomFieldsValues: PropTypes.func,
};

export default UpdateCase;
