import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import useInput from '../../../../hooks/useInput';
import { updateForm } from '../../../../services/unarmed';
import WrapperSave from '../WrapperSave';
import PreviewLabel from './PreviewLabel';
import { FORM_TYPE_VERSION_2 } from '../../../../config/config';
import DatePickerMUI from '../../../../components/DatePickerMUi';

/**
 * Custom Component for Short Text
 * @param {object} props - Component props
 * @param {object} props.data - Component data
 * @param {string} props.version - Form version
 * @param {Function} props.setPagesValues - Function to update page values
 * @param {number} props.componentIndex - Component index
 * @param {string} props.id - Component ID
 * @param {string} props.value - Component value
 * @param {string[]} props.customFieldsValues - Custom field values
 * @param {Function} props.setForm - Function to set form state
 * @param {Function} props.setIsRequestingData - Function to set requesting data state
 * @returns {JSX.Element} CUDate component
 */
export default function CUDate({
  data,
  version,
  setPagesValues,
  componentIndex,
  id,
  // value,
  customFieldsValues,
  setForm,
  setIsRequestingData,
  onSubmitForm,
  withSave = true,
}) {
  const value = customFieldsValues[componentIndex];
  const [date, setDate] = useState(value || null);

  useEffect(() => {
    if (version === FORM_TYPE_VERSION_2) {
      const arrValue = Array.from(customFieldsValues);
      arrValue[componentIndex] = date;

      setPagesValues(arrValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const render = (
    <>
      <PreviewLabel question={data?.question} required={data?.required} />
      <DatePickerMUI
        date={date}
        propWidth="100%"
        type="end"
        setdate={setDate}
        allowEndBeforeStart
        propHeight={45}
        TextFieldComponent={(props) => (
          <TextField
            {...props}
            InputProps={{
              style: {
                height: '100px',
              },
            }}
          />
        )}
      />
    </>
  );

  if (version === FORM_TYPE_VERSION_2 && !withSave)
    return (
      <Box
        style={{ background: '#fff', borderRadius: 5 }}
        margin="20px 20px 0 0"
        width="100%"
        maxWidth="500px"
        display="flex"
        flexDirection="column"
      >
        <Box flexGrow={1}>{render}</Box>
      </Box>
    );

  return <WrapperSave padding={version === FORM_TYPE_VERSION_2 ? '0px' : '20px'}>{render}</WrapperSave>;
}

CUDate.propTypes = {
  data: PropTypes.object.isRequired,
  version: PropTypes.string.isRequired,
  setPagesValues: PropTypes.func.isRequired,
  componentIndex: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  // value: PropTypes.string.isRequired,
  customFieldsValues: PropTypes.array.isRequired,
  setForm: PropTypes.func.isRequired,
  setIsRequestingData: PropTypes.func.isRequired,
  withSave: PropTypes.bool,
  onSubmitForm: PropTypes.func.isRequired,
};
