import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import useInput from '../../hooks/useInput';
import { axiosCatchError, formatPhoneNumber } from '../../utils';
import { createContact, createLinkCaseContact, deleteLinkCaseContact } from '../../services/unarmed';
import CreateContactForm from '../../components/CreateContactForm';
import SearchContacts from '../../components/SearchContacts';
import { parseContactPhoneNumber } from '../Contacts/ModalCreateContact';
import Card from '../../components/Card';
import CasePersonInfo from '../../components/CasePersonInfo';
import { BoxContainer } from '../../styles/GlobalStyles';

const CaseContacts = ({ caseId, defaultContacts, onAddContact, onRemoveContact }) => {
  const [loading, setLoading] = useState(false);

  const [contacts, setContacts] = useState([...defaultContacts]);
  const history = useHistory();

  const search = useInput('');
  const firstName = useInput('');
  const lastName = useInput('');
  const email = useInput('');
  const phoneNumber = useInput('');
  const type = useInput('');
  const companyName = useInput('');

  const onClearFields = () => {
    firstName.setValue('');
    lastName.setValue('');
    phoneNumber.setValue('');
    email.setValue('');
    type.setValue('');
    companyName.setValue('');
  };

  const handleOnSubmit = () => {
    const phoneNumberParsed = parseContactPhoneNumber(phoneNumber.value);

    if (phoneNumber.value && phoneNumberParsed.length < 10) {
      toast.error('The phone number must have 10 digits.');
      return;
    }

    if (email.value === '') {
      toast.error('The email is required.');
      return;
    }

    if (type.value === 'Organization' && companyName.value === '') {
      toast.error('The company name is required.');
      return;
    }

    const data = {
      ...(firstName.value ? { firstName: firstName.value } : {}),
      ...(lastName.value ? { lastName: lastName.value } : {}),
      ...(phoneNumber.value ? { phoneNumber: phoneNumberParsed } : {}),
      ...(type.value ? { type: type.value } : {}),
      ...(type.value === 'Organization' && companyName.value ? { companyName: companyName.value } : {}),
      email: email.value,
    };

    setLoading(true);
    createContact(data)
      .then(async (res) => {
        onClearFields();
        setLoading(false);
        if (res?.data) {
          try {
            await createLinkCaseContact(caseId, res?.data?._id);
          } catch (error) {
            toast.error(error.message);
          }
          toast.success('Contact created successfully!');
          const _contacts = [...contacts, res.data];

          setContacts(_contacts);
        }
      })
      .catch((error) => {
        setLoading(false);
        axiosCatchError(error);
      });
  };

  useEffect(() => {
    setContacts([...defaultContacts]);
  }, [defaultContacts]);

  const handleOnClick = (id) => {
    history.push(`/contacts/${id}`);
  };

  const handleOnClickOpenInNewWindow = (id) => {
    window.open(`/contacts/${id}`, '_blank');
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
      <Card title="Contacts" style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {contacts.length === 0 && (
          <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>No contacts added to this case</Typography>
        )}
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {contacts.map((contact) => (
            <CasePersonInfo
              key={contact?._id}
              data={[
                {
                  label: 'Email',
                  value: contact.email || 'Not specified',
                  valueRaw: contact.email,
                  gridSize: 3,
                },
                {
                  label: 'Contact Name',
                  value: contact.firstName ? `${contact?.firstName} ${contact?.lastName}` : 'Not specified',
                  valueRaw: contact.firstName ? `${contact?.firstName} ${contact?.firstName}` : 'Not specified',
                  gridSize: 3,
                },
                {
                  label: 'Company Name',
                  value: contact.companyName ? contact.companyName : 'Not specified',
                  valueRaw: contact?.companyName || 'Not specified',
                  gridSize: 3,
                },
                {
                  label: 'Phone',
                  value: formatPhoneNumber(contact.phoneNumber) || 'Not specified',
                  valueRaw: contact?.phoneNumber,
                  gridSize: 2,
                },
              ]}
              onClickColumn={() => handleOnClick(contact?._id)}
              onClickColumnInNewWindow={() => handleOnClickOpenInNewWindow(contact?._id)}
              onClickDelete={() => {
                deleteLinkCaseContact(caseId, contact?._id).then(() => {
                  onRemoveContact();
                  toast.success('Contact removed from the case successfully!');
                });
              }}
            />
          ))}
        </Box>
      </Card>
      <BoxContainer marginLeft={2}>
        <SearchContacts
          defaultContacts={contacts}
          search={search}
          linkFunc={createLinkCaseContact}
          linkId={caseId}
          onAddedContact={onAddContact}
        />
        <CreateContactForm
          dataTestId="case-details-new-contact"
          firstName={firstName}
          lastName={lastName}
          email={email}
          phoneNumber={phoneNumber}
          type={type}
          companyName={companyName}
          loading={loading}
          onSubmit={handleOnSubmit}
        />
      </BoxContainer>
    </Box>
  );
};

CaseContacts.propTypes = {
  defaultContacts: PropTypes.array,
  caseId: PropTypes.number,
  onAddContact: PropTypes.func,
  onRemoveContact: PropTypes.func,
};

export default CaseContacts;
