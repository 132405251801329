import React, { useRef, useState } from 'react';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useDrag, useDrop } from 'react-dnd';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import TodayIcon from '@material-ui/icons/Today';
import CancelIcon from '@material-ui/icons/Cancel';
import ITEM_TYPE from '../DnD/types';
import PopperPortal from './PopperPortal';
import schemaIcon from '../assets/schema.png';
import useOrganization from '../hooks/useOrganization';
import ReportSearchInput, { onFilterCubes } from './ReportSearchInput';

const useStyles = makeStyles({
  item: {
    height: 45,
    background: '#fff',
    borderRadius: 4,
    border: '1px solid #7070701F',
    padding: '15px 16px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  label: {
    fontSize: 14,
    color: '#00000061',
  },

  seletedItem: {
    color: '#000000DE',
    fontSize: 14,
    fontWeight: '400',
  },
  img: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  hoverItem: {
    cursor: 'pointer',
    borderRadius: 4,
    '& :hover': {
      transition: 'ease .3s all',
      background: '#f2f2f2',
    },
  },
});

export default function ReportDnDItem({ index, id, moveItem, label, order, cubes, type, setItems, value }) {
  const classes = useStyles();
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const { organization } = useOrganization();
  const [search, setSearch] = useState('');

  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveItem(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { type: ITEM_TYPE, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const opacity = isDragging ? 0 : 1;

  const handleClick = (item) => {
    setItems((prevState) => {
      const stateCopy = [...prevState];
      stateCopy[index].value = item;
      return stateCopy;
    });

    setOpen(false);
  };

  const handleRemove = () => {
    setItems((prevState) => {
      const stateCopy = [...prevState];
      stateCopy[index].value = null;
      return stateCopy;
    });
  };

  const cubesFiltered = onFilterCubes(search, cubes);
  const checkedEmptyCubes = cubesFiltered.filter((cube) => {
    if (type === 'dimensions') return cube.dimensions.length > 0;

    if (type === 'measures') return cube.measures.length > 0;
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      ref={ref}
      isDraggin={isDragging}
      style={{ opacity }}
      marginBottom="10px"
      position="relative"
    >
      <DragIndicatorIcon htmlColor="#6D7385" style={{ marginRight: 7, cursor: isDragging ? 'grabbing' : 'grab' }} />
      <Box className={classes.item} onClick={() => setOpen(true)}>
        <Box display="flex" alignItems="center" width="90%">
          {open ? (
            <ReportSearchInput search={search} setSearch={setSearch} />
          ) : (
            <>
              {value ? (
                <>
                  <Typography className={classes.seletedItem}>{`${order}. ${value?.shortTitle}`}</Typography>
                </>
              ) : (
                <Typography className={classes.label}>{`${order}. ${label}`}</Typography>
              )}
            </>
          )}
        </Box>
      </Box>
      {value && (
        <CancelIcon
          fontSize="small"
          htmlColor="#6D7385"
          style={{
            position: 'absolute',
            right: 15,
            zIndex: 8,
            cursor: 'pointer',
          }}
          onClick={handleRemove}
        />
      )}
      <PopperPortal open={open} setOpen={setOpen}>
        <Box
          width={300}
          height={checkedEmptyCubes.length > 0 ? 400 : 200}
          style={{ background: '#fff', zIndex: 99, overflowY: 'auto' }}
        >
          {checkedEmptyCubes.length > 0 ? (
            <>
              {type === 'dimensions' &&
                cubesFiltered.map((cube, idx) => (
                  <Dimensions
                    dimension={cube}
                    key={`dimmension#${idx}`}
                    onClick={handleClick}
                    organizationId={organization?._id}
                  />
                ))}
              {type === 'measures' &&
                cubesFiltered.map((cube, idx) => (
                  <Measures
                    measures={cube}
                    key={`measures#${idx}`}
                    onClick={handleClick}
                    organizationId={organization?._id}
                  />
                ))}
            </>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" marginTop={1} width="100%" height="90%">
              <Typography>No results</Typography>
            </Box>
          )}
        </Box>
      </PopperPortal>
    </Box>
  );
}

function Dimensions({ dimension, onClick, organizationId }) {
  const classes = useStyles();
  return dimension.dimensions.length && dimension.dimensions.filter((dim) => dim.isVisible).length ? (
    <Box padding="7px 12px">
      <Box display="flex" alignItems="center">
        <img className={classes.img} src={schemaIcon} />
        <Typography style={{ fontSize: 14 }}>{dimension?.title?.replace('-', '')}</Typography>
      </Box>
      {dimension.dimensions
        .filter((dim) => dim.isVisible)
        .map((dim) => {
          if (dim?.meta?.allowedOrganizations) {
            if (dim?.meta?.allowedOrganizations?.includes(organizationId)) {
              return dim;
            }
            return null;
          }

          return dim;
        })
        .filter((dim) => dim)
        .map((dimm, index) => (
          <Box className={classes.hoverItem} onClick={() => onClick(dimm)} key={`${dimm.name}+${index}`}>
            <Box display="flex" alignItems="center" padding="19px 20px">
              {dimm.type === 'string' && <TextFormatIcon fontSize="small" />}
              {dimm.type === 'time' && <TodayIcon fontSize="small" style={{ marginRight: 10 }} />}
              <Typography style={{ fontSize: 14 }}>{dimm?.title || dimm?.shortTitle}</Typography>
            </Box>
          </Box>
        ))}
    </Box>
  ) : (
    <div />
  );
}

function Measures({ measures, onClick, organizationId }) {
  const classes = useStyles();
  console.log('MEASURES', measures)
  return measures.measures.length && measures.measures.filter((dim) => dim.isVisible).length ? (
    <Box padding="7px 12px">
      <Box display="flex" alignItems="center">
        <img className={classes.img} src={schemaIcon} />
        <Typography style={{ fontSize: 14 }}>{measures?.title?.replace('-', '')}</Typography>
      </Box>

      {measures.measures
        .filter((measure) => measure.isVisible)
        .map((measure) => {
          if (measure?.meta?.allowedOrganizations) {
            if (measure?.meta?.allowedOrganizations?.includes(organizationId)) {
              return measure;
            }
            return null;
          }

          return measure;
        })
        .filter((measure) => measure)
        .map((meas, index) => (
          <Box className={classes.hoverItem} onClick={() => onClick(meas)} key={index}>
            <Box display="flex" alignItems="center" padding="19px 20px">
              {meas.type === 'string' && <TextFormatIcon fontSize="small" />}
              {meas.type === 'time' && <TodayIcon fontSize="small" style={{ marginRight: 10 }} />}
              <Typography style={{ fontSize: 14 }}>{meas?.title || meas?.shortTitle}</Typography>
            </Box>
          </Box>
        ))}
    </Box>
  ) : (
    <div />
  );
}
