import { Box, CircularProgress, Typography, FormControlLabel } from '@material-ui/core';
import { CustomSwitch } from '../formEngine/components/WrapperComponent';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { getFormNotesLogs, onArchiveNoteForm, onUnArchiveNoteForm, updateNotesSortOrder } from '../services/unarmed';
import { downloadImage } from '../utils/libs';
import TimeLineItem from '../pages/Activity/TimeLineItem';
import CarouselModal from './CarouselModal';
import { Context } from '../Context';
import Note from './Note';
import Card from './Card';
import { CustomModalDelete } from './CustomModalDelete';
import TimeLineItemNotes from '../pages/Activity/TimeLineItemNotes';
import useOrganization from '../hooks/useOrganization';

export const UserInfo = ({ user, userInfo }) => {
  const email = user?.email || userInfo?.email;
  return email ? <strong>{email}</strong> : 'A user';
};

UserInfo.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
    age: PropTypes.number.isRequired,
  }).isRequired,
};

export function NoteLog({
  handleDeleteNote,
  handleRestoreClick,
  _id,
  createdAt,
  files,
  comment,
  user,
  userInfo,
  canEdit,
  canDelete,
  onEditClick,
  editing,
  onCancelEditingClick,
  updatedAt,
  editedByInfo,
  onDownloadImage,
  archivedDate,
  subject = 'case',
}) {
  const { myUser } = useContext(Context);
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = useState(false)
  const [filesSelected, setFilesSelected] = React.useState({ files: [], selectedFileIndex: 0 });
  const [loadingOpenFiles, setLoadingOpenFiles] = React.useState([]);

  const handleOpen = (filesOp) => {
    setOpen(true);
    const filesToOpen = filesOp.filter((flop) => flop.mimetype.includes('image') || flop.mimetype.includes('video'));
    setFilesSelected({ files: filesToOpen, selectedFileIndex: 0 });
    setLoadingOpenFiles(filesToOpen.map(() => ({ loading: false })));
  };

  const handleClickEvents = (fl) => ({
    onDownload: () => downloadImage(fl.download_url, fl.name),
    onClick: () => handleOpen(files),
  });

  const renderName = (wholeName = false) => {
    if (user && !user?.photo?.public_url && !wholeName) {
      return `${user?.email[0]?.toUpperCase()} ${user?.email[1]?.toUpperCase()}`;
    }
    if (user && wholeName) {
      return user?.email;
    }

    if (!user && userInfo && !wholeName) {
      return `${userInfo?.email[0]?.toUpperCase()} ${userInfo?.email[1]?.toUpperCase()}`;
    }
    if (!user && userInfo && wholeName) {
      return userInfo?.email;
    }

    if (!user && !userInfo && !wholeName) {
      return 'DU';
    }
    if (!user && !userInfo && wholeName) {
      return 'Deleted User';
    }
  };

  return (
    <>
      <TimeLineItemNotes
        onRestoreClick={handleRestoreClick}
        archivedDate={archivedDate}
        createdAt={createdAt}
        profileImg={user?.photo?.public_url}
        useDefaultImage={!user && !userInfo}
        avatar={renderName()}
        canEdit={canEdit && myUser?._id === user?._id}
        canDelete={canDelete}
        onEditClick={() => onEditClick({ _id, comment })}
        onDeleteClick={() => setOpenDelete(true)}
        editing={editing}
        onCancelEditingClick={onCancelEditingClick}
        updatedAt={updatedAt}
        editedByInfo={editedByInfo}
      >
        <Note
          comment={comment}
          files={files}
          user={user}
          userInfo={userInfo}
          subject={subject}
          onClickFile={handleClickEvents}
        />
        <CustomModalDelete
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          fileName={comment}
          onConfirm={handleDeleteNote}
        />

      </TimeLineItemNotes>
      <CarouselModal
        open={open}
        setOpen={setOpen}
        filesSelected={filesSelected}
        setLoadingOpenFiles={setLoadingOpenFiles}
        loadingOpenFiles={loadingOpenFiles}
        onDownloadImage={onDownloadImage}
      />
    </>
  );
}

// PropTypes for NoteLog
NoteLog.propTypes = {
  createdAt: PropTypes.instanceOf(Date).isRequired,
  files: PropTypes.arrayOf(PropTypes.string).isRequired,
  comment: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string,
    name: PropTypes.string.isRequired,
    photo: PropTypes.shape({
      public_url: PropTypes.string,
    }),
  }),
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
    age: PropTypes.number.isRequired,
  }),
  subject: PropTypes.string,
};

export default function NoteLogs({ id, isRequestingData, canEdit, onEditClick, editingLog, onCancelEditingClick }) {
  const { myUser } = useContext(Context);
const { organization } = useOrganization();
  const [Logs, setLogs] = useState([]);
  const [LogsShow, setLogsShow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [archivedOnly, setArchivedOnly] = useState(false);

  // Load sort order from localStorage or fallback to user's backend setting
  const initialSortOrder = localStorage.getItem('formNotesSortOrder') || (myUser?.notesSortOrderDesc?.cases ? 'desc' : 'asc');
  const [sortOrder, setSortOrder] = useState(initialSortOrder);

  const sortByCreatedAt = (data, order = 'desc') => {
    return data.data.sort((x, y) => {
      const dateX = new Date(x.createdAt).getTime();
      const dateY = new Date(y.createdAt).getTime();

      if (order === 'asc') {
        return dateX - dateY;
      }
      return dateY - dateX;
    });
  };

  const getCanDelete = (user) => {
    if(myUser.role === 'admin' && organization?.permissions?.[myUser?.role]?.canDeleteNotesCases !== 0 ){
      return true
    }else if(organization?.permissions?.[myUser?.role]?.canDeleteNotesCases !== 0 && (myUser._id === user._id)  ){
      return true
    }else{
      return false
    }
  }

  const toggleSortOrder = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    const updatedSortOrder = newSortOrder === 'desc';

    updateNotesSortOrder(myUser?._id, 'cases', updatedSortOrder)
      .then(() => {
        setSortOrder(newSortOrder);
        localStorage.setItem('formNotesSortOrder', newSortOrder); // Persist in localStorage
      })
      .catch((error) => {
        toast.error('Failed to update sort order');
      });
  };

  const getNoteLogs = () => {
    setLoading(true);

    getFormNotesLogs(id)
      .then((res) => {
        setLogs(sortByCreatedAt(res.data, sortOrder));
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.message);
        setLoading(false);
      });
  };

  const handleArchiveOnly = () => {
    setArchivedOnly(!archivedOnly)
  }

  useEffect(() => {
    if (id) {
      getNoteLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sortOrder]);

  useEffect(() => {
    getNoteLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestingData]);

  useEffect(() => {
    if(archivedOnly) {
      const newLogs = Logs.filter(log => log.hasOwnProperty('archivedDate') && log.archivedDate !== null);
      setLogsShow(newLogs)
    }else{
      const newLogsWithoutArchivedDate = Logs.filter(log => !log.hasOwnProperty('archivedDate'));
      setLogsShow(newLogsWithoutArchivedDate)
    }

  }, [Logs, archivedOnly])

  return (
    <Card
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" style={{ color: '#575A66', margin: 0, fontSize: '16px', fontWeight: '500' }}>Notes</Typography>
            <Box style={{ display: 'flex'}}>
              <Box>
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={sortOrder === 'desc'}
                      onChange={toggleSortOrder}
                      color="primary"
                    />
                  }
                  label={sortOrder === 'desc' ? 'Most Recent' : 'Oldest'}
                  labelPlacement="end"
                  style={{ marginLeft: '500px' }}
                />
              </Box>
              <Box>
                <FormControlLabel
                    style={{ margin: 0 }}
                    control={<CustomSwitch checked={archivedOnly} onChange={handleArchiveOnly} />}
                    labelPlacement="end"
                    label="Archived only"
                  />
              </Box>
            </Box>
        </Box>
      }
    >
      {loading && (
        <Box height="50px" justifyContent="center" alignItems="center" display="flex">
          <CircularProgress color="#364F74" size="25px" />
        </Box>
      )}
      {!LogsShow?.length && !loading ? (
        <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
          No notes added to this case
        </Typography>
      ) : null}
      {LogsShow?.map(({ _id, createdAt, files, comment, user, userInfo, updatedAt, editedByInfo, form, archivedDate }, i) => (
        <NoteLog
          handleDeleteNote={async() => {
            await onArchiveNoteForm(form, _id)
            getNoteLogs()
          }}
          handleRestoreClick={async() => {
            await onUnArchiveNoteForm(form, _id)
            getNoteLogs()
          }}
          archivedDate={archivedDate}
          _id={_id}
          createdAt={createdAt}
          files={files}
          comment={comment}
          user={user}
          userInfo={userInfo}
          key={i}
          canEdit={canEdit}
          canDelete={getCanDelete(user) }
          onEditClick={onEditClick}
          editing={editingLog?._id === _id}
          onCancelEditingClick={onCancelEditingClick}
          updatedAt={updatedAt}
          editedByInfo={editedByInfo}
        />
      ))}
    </Card>
  );
}

NoteLogs.propTypes = {
  id: PropTypes.number.isRequired,
  isRequestingData: PropTypes.bool.isRequired,
};