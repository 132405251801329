import React, { useCallback, Fragment, useMemo, useRef, useState } from 'react';
import { Box, Divider, makeStyles, MenuItem, Typography } from '@material-ui/core';
import styled from 'styled-components';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import WcIcon from '@material-ui/icons/Wc';
import TitleIcon from '@material-ui/icons/Title';
import RadioButtonIcon from '@material-ui/icons/RadioButtonChecked';
import CheckboxIcon from '@material-ui/icons/CheckBox';
import ShortTextIcon from '@material-ui/icons/ShortText';
import LongTextIcon from '@material-ui/icons/Notes';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LinkIcon from '@material-ui/icons/Link';
import RoomIcon from '@material-ui/icons/Room';
import PersonIcon from '@material-ui/icons/Person';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { useDrag } from 'react-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import shortid from 'shortid';
import { getConfig } from '../../formEngine/config/Config';
import { Renderer } from '../../formEngine/config/Renderer';
import { SECTION } from '../../formEngine/utils/constants';

import { ReactComponent as CalendarIcon } from '../../assets/icons/today_black_24dp.svg';
import { ReactComponent as YesNoIcon } from '../../assets/icons/rule_black_24dp.svg';
import PopperPortal from '../../components/PopperPortal';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    padding: 28,
    minHeight: 195,
    position: 'relative',
    borderRadius: 8,
    boxShadow: '0px 3px 6px #0000000D',
    backgroundColor: '#fff',
  },
  ButtonText: {
    background: '#2E66FE 0% 0% no-repeat padding-box',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    padding: '10px 20px',
    color: '#fff',
    fontSize: 14,
    fontWeight: '500',
    width: 152,
    borderRight: '1px solid #fff',
    textAlign: 'center',
  },
  dropdownButton: {
    background: '#2E66FE 0% 0% no-repeat padding-box',
    width: 32,
    padding: '10px 20px',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    cursor: 'pointer',
    position: 'relative',
  },
  altText: {
    fontSize: 14,
    color: '#000000AD',
  },
  dndContainer: {
    display: 'flex',
    alignItems: 'center',
    transition: 'ease all .3s',
  },
}));

const renderComponentsArr = (hideDemographics = false) => [
  {
    name: 'Title',
    Icon: TitleIcon,
    data: {
      text: '',
    },
    type: 'title',
  },
  {
    name: 'Subtitle',
    Icon: TitleIcon,
    data: {
      text: '',
    },
    type: 'subtitle',
  },
  {
    name: 'Single Choice',
    Icon: RadioButtonIcon,

    data: {
      question: '',
      description: '',
      required: false,
    },
    type: 'singleChoice',
  },

  {
    name: 'Multiple Choices',
    Icon: CheckboxIcon,

    data: {
      question: '',
      description: '',
      required: false,
    },
    type: 'multipleChoices',
  },
  {
    name: 'Dropdown',
    Icon: ArrowDropDownIcon,

    data: {
      question: '',
      description: '',
      required: false,
    },
    type: 'dropdown',
  },

  {
    name: 'Yes/No',
    Icon: YesNoIcon,

    data: {
      question: '',
      description: '',
      required: false,
    },
    type: 'yesNo',
  },
  {
    name: 'Short Text',
    Icon: ShortTextIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    type: 'shortText',
  },
  {
    name: 'Long Text',
    Icon: LongTextIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    type: 'longText',
  },
  {
    name: 'File upload',
    Icon: CloudUploadIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    type: 'fileUpload',
  },
  {
    name: 'URL',
    Icon: LinkIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    type: 'url',
  },
  ...(!hideDemographics
    ? [
        {
          name: 'Demographics',
          Icon: WcIcon,

          // data: {
          //   question: '',
          //   description: '',
          //   required: false,
          // },
          components: [
            {
              name: 'Date Of Birth',
              type: 'dateOfBirth',
              id: 'dob-EMJmrLMK_',
              shouldRenderToggle: true,
              data: {
                visible: true,
                question: 'Date Of Birth',
                required: false,
              },
            },
            {
              name: 'Ethnicity',
              type: 'ethnicity',
              id: 're-MN-A6Xlp4',
              shouldRenderToggle: true,
              data: {
                visible: false,
                question: 'Ethnicity',
                required: false,
                multipleChoice: false,
                options: [
                  'Black or African American',
                  'Hispanic or Latinx',
                  'Asian',
                  'Native Hawaiian or Pacific Islander',
                  'American Indian or Alaskan Native',
                  'Middle Eastern or South Asian',
                  'Multiracial',
                  'White',
                  'Not Listed',
                  'Prefer not to answer',
                ],
              },
            },
            {
              name: 'Gender',
              type: 'gender',
              id: 'ge-Z5QyMOjRr',
              shouldRenderToggle: true,
              data: {
                visible: true,
                question: 'Gender',
                required: false,
                multipleChoice: false,
                options: [
                  'Male',
                  'Female',
                  'Trans Male',
                  'Trans Female',
                  'Genderqueer / Gender Non-binary',
                  'Not Listed',
                  'Prefer not to answer',
                ],
              },
            },
            {
              name: 'Race',
              type: 'race',
              id: 're-MN-A6Xlp3',
              shouldRenderToggle: true,
              data: {
                visible: false,
                question: 'Race',
                required: false,
                multipleChoice: false,
                options: [
                  'Black or African American',
                  'Hispanic or Latinx',
                  'Asian',
                  'Native Hawaiian or Pacific Islander',
                  'American Indian or Alaskan Native',
                  'Middle Eastern or South Asian',
                  'Multiracial',
                  'White',
                  'Not Listed',
                  'Prefer not to answer',
                ],
              },
            },

            {
              name: 'Race/Ethnicity',
              type: 'raceEthnicity',
              id: 're-MN-A6Xlp2',
              shouldRenderToggle: true,
              data: {
                visible: true,
                question: 'Race/Ethnicity',
                required: false,
                multipleChoice: false,
                options: [
                  'Black or African American',
                  'Hispanic or Latinx',
                  'Asian',
                  'Native Hawaiian or Pacific Islander',
                  'American Indian or Alaskan Native',
                  'Middle Eastern or South Asian',
                  'Multiracial',
                  'White',
                  'Not Listed',
                  'Prefer not to answer',
                ],
              },
            },

            {
              name: 'Sexual Orientation',
              type: 'sexualOrientation',
              id: 'so-I2csJnaXb',
              shouldRenderToggle: true,
              data: {
                question: 'Sexual Orientation',
                required: false,
                multipleChoice: false,
                visible: true,
                options: [
                  'Bisexual',
                  'Gay Male',
                  'Lesbian',
                  'Heterosexual or Straight',
                  'Pansexual',
                  'Asexual',
                  'Not Listed',
                  'Prefer not to answer',
                ],
              },
            },
          ],
          type: 'demographics',
        },
      ]
    : []),
  {
    name: 'Contact Information',
    Icon: PersonIcon,
    data: {
      question: '',
      description: '',
    },
    type: 'contactInformation',
  },
  {
    name: 'Address Information',
    Icon: RoomIcon,
    data: {
      question: '',
      description: '',
    },
    type: 'addressInformation',
  },
  {
    name: 'Date',
    Icon: CalendarIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    type: 'date',
  },
  {
    name: 'Hour',
    Icon: AccessTimeIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    type: 'hour',
  },
  {
    name: 'Phone',
    Icon: PhoneIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    type: 'phone',
  },
  {
    name: 'Email',
    Icon: EmailIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    type: 'email',
  },
];

/**
 * @description This component is used to render a section of the form
 * @param {function} handleChangeSectionName - function to change the name of the section
 * @param {array} components - array of components of the section
 * @param {function} addComponent - function to add a component to the section
 * @param {function} deleteComponent - function to delete a component of the section
 * @param {function} handleDrop - function to handle the drop of a component
 * @param {object} data - data of the section
 * @param {string} path - path of the section
 * @param {array} pages - array of pages of the form
 * @param {function} onChangeDataInput - function to change the data of a component
 * @param {function} onChangeOptions - function to change the options of a component
 * @param {function} onUpdateJson - function to update the json of the form
 * @param {function} deleteSection - function to delete the section
 * @param {function} duplicateComponent - function to duplicate a component
 * @param {function} setModal - function to set the modal
 * @param {object} refActionModal - ref of the modal
 * @param {boolean} isDefaultForm - boolean to know if the form is default
 */

export default function Section({
  handleChangeSectionName,
  components,
  addComponent,
  deleteComponent,
  handleDrop,
  data,
  path,
  pages,
  onChangeDataInput,
  onChangeOptions,
  onUpdateJson,
  deleteSection,
  duplicateComponent,
  setModal,
  refActionModal,
  isDefaultForm,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const [isHover, setIsHover] = useState(false);

  /**
   * @description This hook is used to drag the section in the form
   */
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: SECTION,
      id: data.id,
      components,
      path,
    },
    type: SECTION,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  /**
   * @description This function is used to check if the section contains the demographics component
   */
  const checkDemographicsComponent = () => {
    let containsDemographics = false;
    pages.forEach((page) => {
      page.sections.forEach((section) => {
        section.components.forEach((component) => {
          if (component.type === 'demographics') {
            containsDemographics = true;
          }
        });
      });
    });

    return containsDemographics;
  };
  checkDemographicsComponent();

  const handleDuplicate = useCallback(
    (sectionIx, componentIx) => duplicateComponent(renderComponentsArr, sectionIx, componentIx),
    [renderComponentsArr]
  );

  const config = useMemo(
    () =>
      getConfig({
        componentsFromServer: components,
        deleteComponent,
        onChangeDataInput,
        onChangeOptions,
        onUpdateJson,
        refActionModal, // padre santo
        setModal,
        handleDrop,
      }),
    [components]
  );

  /**
   * @description This function is used to select a component to add to the section
   */
  const handleSelectComponent = (component) => {
    setOpen(false);
    addComponent(component);
  };

  /**
   * @description This function is used to render the content of the section
   */
  const renderSectionContent = () => (
    <Box className={classes.container}>
      {isDefaultForm && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            background: 'rgba(0,0,0,0.01)',
          }}
        />
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box style={{ width: '100%', height: 1, background: '#6D738526' }} />
        {/* <Box padding="0 20px">
          <InputSection
            value={data.name}
            onChange={(e) => handleChangeSectionName(e, +path)}
            placeholder="Enter section name here"
          />
        </Box> */}
        <Box style={{ width: '100%', height: 1, background: '#6D738526' }} />
        <DeleteIcon
          onClick={() => {
            setModal(true);
            refActionModal.current = {
              nameAction: 'section',
              actionPress: () => deleteSection(parseInt(path)),
            };
          }}
          htmlColor="#6D7385"
          style={{ marginLeft: 18, cursor: 'pointer' }}
        />
      </Box>
      <Box marginTop="30px">
        <Renderer duplicateComponent={handleDuplicate} config={config} handleDrop={handleDrop} path={path} />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" margin="34px 0">
        <Box display="flex" alignItems="center" onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
          <Box className={classes.ButtonText}>ADD QUESTION</Box>
          <Box className={classes.dropdownButton}>
            <ArrowDropDownIcon htmlColor="#fff" />
            <PopperPortal open={open} setOpen={setOpen} left="0px" top="45px">
              <Box width={249} borderRadius={4} style={{ background: '#fff' }} boxShadow="0px 3px 6px #0000000D">
                {renderComponentsArr(checkDemographicsComponent()).map((component, idx) => (
                  <Fragment key={idx}>
                    {idx === 2 && <Divider style={{ margin: '8px 0' }} />}
                    {idx === 6 && <Divider style={{ margin: '8px 0' }} />}
                    {idx === 8 && <Divider style={{ margin: '8px 0' }} />}
                    {idx === 10 && <Divider style={{ margin: '8px 0' }} />}
                    <MenuItem
                      key={idx}
                      onClick={() =>
                        handleSelectComponent({
                          name: component.name,
                          ...(component.components?.length ? { components: component.components } : {}),
                          type: component.type,
                          id: shortid.generate(),
                          data: component.data,
                        })
                      }
                    >
                      <Box display="flex" alignItems="center">
                        <component.Icon fill="#6D7385" htmlColor="#6D7385" style={{ fontSize: 15 }} />
                        <Typography style={{ marginLeft: 10, fontSize: 14 }}>{component.name}</Typography>
                      </Box>
                    </MenuItem>
                  </Fragment>
                ))}
              </Box>
            </PopperPortal>
          </Box>
        </Box>
      </Box>
      <Typography align="center" className={classes.altText}>
        Or choose from the question bank to get started
      </Typography>
    </Box>
  );
  return isDefaultForm ? (
    <>{renderSectionContent()}</>
  ) : (
    <div
      ref={ref}
      style={{ opacity }}
      className={classes.dndContainer}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <DragIndicatorIcon
        htmlColor="#6D7385"
        style={{
          marginRight: 7,
          cursor: isDragging ? 'grabbing' : 'grab',
          opacity: isHover ? 1 : 0,
          transition: 'ease all .3s',
        }}
      />
      {renderSectionContent()}
    </div>
  );
}

const InputSection = styled.input`
  color: #000000de;
  border: 0;
  outline: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  ::placeholder {
    color: #00000061;
    font-weight: 500;
  }
`;
