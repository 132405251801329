import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { toast } from 'react-toastify';
import useInput from '../../../../hooks/useInput';
import { updateForm } from '../../../../services/unarmed';
import WrapperSave from '../WrapperSave';
import PreviewLabel from './PreviewLabel';
import { FORM_TYPE_VERSION_2 } from '../../../../config/config';

/**
 * Component for Single Choice input
 * @param {object} props - Component props
 * @param {object} props.data - Data object for single choice
 * @param {string} props.version - Version of the form
 * @param {Function} props.setPagesValues - Function to update page values
 * @param {number} props.componentIndex - Index of the component
 * @param {string} props.id - ID of the component
 * @param {string} props.value - Current value
 * @param {array} props.customFieldsValues - Array of custom field values
 * @param {Function} props.setForm - Function to set form data
 * @param {Function} props.setIsRequestingData - Function to set data request status
 * @returns {JSX.Element} Single choice component
 */
export default function CUSingleChoice({
  data,
  version,
  setPagesValues,
  componentIndex,
  id,
  // value,
  customFieldsValues,
  setForm,
  setIsRequestingData,
  onSubmitForm,
  withSave = true,
}) {
  const value = customFieldsValues[componentIndex];
  const singleChoice = useInput(value || '');

  /**
   * Handles save operation
   */
  const onSave = async () => {
    setIsRequestingData(true);
    try {
      const arrValue = Array.from(customFieldsValues);
      arrValue[componentIndex] = singleChoice.value;
      const json = {
        customFields: arrValue,
      };
      const res = version === FORM_TYPE_VERSION_2 ? await onSubmitForm(id, json) : await updateForm(id, json);
      setForm((prevState) => ({ ...prevState, ...res.data }));
      toast.success('Case Updates have been updated');
      setIsRequestingData(false);
    } catch (error) {
      toast.error(error.message);
      setIsRequestingData(false);
    }
  };

  useEffect(() => {
    if (version === FORM_TYPE_VERSION_2) {
      const arrValue = Array.from(customFieldsValues);
      arrValue[componentIndex] = singleChoice.value;

      setPagesValues(arrValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleChoice.value]);

  const render = (
    <>
      <PreviewLabel question={data.question} required={data?.required} />
      <RadioGroup
        aria-label="singleChoice"
        name={`singleChoice-${componentIndex}`}
        value={singleChoice.value}
        onChange={singleChoice.onChange}
      >
        {data?.options?.map((option, index) => (
          <Box display="flex" alignItems="center" key={index} marginBottom="10px">
            <FormControlLabel
              value={option}
              control={
                <Radio color="default" inputProps={{ 'aria-label': 'D' }} style={{ padding: 0, marginRight: 7 }} />
              }
              label={option}
              style={{ color: '#747474', marginLeft: 0 }}
            />
          </Box>
        ))}
      </RadioGroup>
    </>
  );

  if (version === FORM_TYPE_VERSION_2 && !withSave)
    return (
      <Box
        style={{ background: '#fff', borderRadius: 5 }}
        margin="20px 20px 0 0"
        width="100%"
        maxWidth="500px"
        display="flex"
        flexDirection="column"
      >
        <Box flexGrow={1}>{render}</Box>
      </Box>
    );

  return (
    <WrapperSave padding={version === FORM_TYPE_VERSION_2 ? '0px' : '20px'} onSave={onSave}>
      {render}
    </WrapperSave>
  );
}

CUSingleChoice.propTypes = {
  data: PropTypes.object.isRequired,
  version: PropTypes.string.isRequired,
  setPagesValues: PropTypes.func.isRequired,
  componentIndex: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  // value: PropTypes.string.isRequired,
  customFieldsValues: PropTypes.array.isRequired,
  setForm: PropTypes.func.isRequired,
  setIsRequestingData: PropTypes.func.isRequired,
  withSave: PropTypes.bool,
  onSubmitForm: PropTypes.func.isRequired,
};
