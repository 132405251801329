import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  formSection: {
    fontSize: 14,
    color: '#3f51b5',
    fontWeight: '500',
    margin: '20px 0',
  },
  containerTitle: {
    padding: '10px 20px',
    borderBottom: '1px solid #e2e2e2',
  },
  resaltText: {
    color: '#4b7bff',
    fontWeight: 600,
    fontSize: 13,
  },
  label: {
    fontWeight: '500',
    fontSize: 14,
    margin: '10px 0 10px',
  },
  button: {
    background: ' #4b7bff',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
  },
  labelError: {
    fontSize: 12,
    color: 'red',
  },
}));

/**
 * Wrapper Component for Save Operation
 * @param {object} props - Component props
 * @param {JSX.Element} props.children - Child components
 * @param {object} props.styles - Custom styles object
 * @param {Function} props.onSave - Function to handle save operation
 * @returns {JSX.Element} WrapperSave component
 */
export default function WrapperSave({ children, styles, padding = '20px', onSave }) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  /**
   * Handles custom save operation
   */
  const onSaveCustom = async () => {
    try {
      setLoading(true);
      await onSave();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Box
      style={{ background: '#fff', borderRadius: 5, ...styles }}
      boxShadow={padding === '0px' ? 'none' : '0 3px 6px #00000005'}
      padding={padding}
      margin="20px 20px 0 0"
      width="100%"
      maxWidth="500px"
      display="flex"
      flexDirection="column"
    >
      <Box flexGrow={1}>{children}</Box>
      <Box display="flex" justifyContent="flex-end" marginTop="15px">
        <Button variant="contained" className={classes.button} onClick={onSaveCustom} disabled={loading}>
          {loading ? <CircularProgress color="#fff" size="25px" /> : 'Save'}
        </Button>
      </Box>
    </Box>
  );
}

WrapperSave.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};
