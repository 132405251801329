/* eslint-disable no-plusplus */
import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Papa from 'papaparse';
import FormStepper from '../../components/FormStepper';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import { Context } from '../../Context';
import UpgradeModal from '../../components/UpgradeModal';
import useOrganization from '../../hooks/useOrganization';
import useInput from '../../hooks/useInput';
// import { matchfieldOptions } from '../../utils/options';

const steps = ['File Import', 'Match Field', 'Preview'];
const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    width: '100%',
  },
}));

const FileImport = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [csv, setCsv] = useState(null);
  const [forms, setForms] = useState([]);
  const [csvErrors, setCsvErrors] = useState(null);
  const [orderedHeaders, setOrderedHeaders] = useState([]);
  const [rowSelected, setRowSelected] = useState({});
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedModule, setSelectedModule] = useState('');
  const { setActive } = useContext(Context);
  const { organization } = useOrganization();
  const selectedForm = useInput('');

  const onResetMatchFieldOptions = () => {
    const setOffOptions = selectOptions.map((option) => {
      option.disabled = false;
      return option;
    });
    setSelectOptions(setOffOptions);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => onResetMatchFieldOptions(), []);

  useEffect(() => {
    setActive('/import');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processData = (data) => {
    const list = [];
    const headers = data[0];
    const r = data[data.length - 1].length === 1 ? data.slice(1, -1) : data.slice(1);

    for (const row of r) {
      const obj = {};
      let counter = 0;
      for (let i = 0; i < headers.length; i++) {
        if (headers[i] === 'Investigator') {
          counter++;
          if (counter > 1) {
            obj[headers[i] + '1'] = row[i];
          } else {
            obj[headers[i]] = row[i];
          }
        } else {
          obj[headers[i]] = row[i];
        }
      }
      list.push(obj);
    }

    let investigatorCounter = 0;
    const clms = data[0].map((c) => {
      if (c === 'Investigator') {
        investigatorCounter++;
        if (investigatorCounter > 1) {
          return {
            name: c + '1',
            selector: c + '1',
          };
        }
      }
      return {
        name: c,
        selector: c,
      };
    });

    console.log({ list });
    const filterEmptyList = list.filter((item) => {
      const keys = Object.keys(item);
      const nullValuesCount = keys.reduce((acum, currentValue) => {
        if (!item[currentValue]) {
          return acum + 1;
        }
        return acum;
      }, 0);
      return nullValuesCount !== keys.length;
    });
    console.log({ clms, filterEmptyList });
    setRows(filterEmptyList);
    setColumns(clms);
  };

  const handleFileUpload = (file) => {
    setCsv(file);
    // const reader = new FileReader();
    // reader.onload = (evt) => {
    //   /* Parse data */
    //   const bstr = evt.target.result;
    //   const wb = XLSX.read(bstr, { type: 'binary' });
    //   /* Get first worksheet */
    //   const wsname = wb.SheetNames[0];
    //   const ws = wb.Sheets[wsname];
    //   /* Convert array of arrays */
    //   const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
    //   console.log(data);
    //
    // };
    // reader.readAsBinaryString(file);

    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      Papa.parse(file, {
        complete(results) {
          processData(results.data);
          setActiveStep(1);
        },
        skipEmptyLines: true,
      });
    }, 2000);
  };

  const getJsonHeaders = () => {
    const keys = Object.keys(forms[0]);
    let fullKeys = [];
    keys.forEach((key) => {
      if (Array.isArray(forms[0][key])) {
        fullKeys = [...fullKeys, key];
      } else if (typeof forms[0][key] === 'object') {
        const nestedKeys = Object.keys(forms[0][key]);
        fullKeys = [...fullKeys, ...nestedKeys];
      } else {
        fullKeys = [...fullKeys, key];
      }
    });

    return fullKeys.slice(1);
  };

  const onSetRowSelected = (index, obj) => {
    setRowSelected({ ...rowSelected, [index]: { ...obj } });
  };

  const onRenderStep = () => {
    if (activeStep === 0) {
      return (
        <Step1
          orgId={organization?._id}
          csv={csv}
          handleFileUpload={handleFileUpload}
          setSelectOptions={setSelectOptions}
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
          selectedForm={selectedForm}
        />
      );
    }
    if (activeStep === 1) {
      return (
        <Step2
          rows={rows}
          columns={columns}
          setStep={setActiveStep}
          formTypeId={selectedForm.value}
          setCsv={setCsv}
          prepareForms={setForms}
          setCsvErrors={setCsvErrors}
          setOrderedHeaders={setOrderedHeaders}
          setRowSelected={onSetRowSelected}
          rowValues={rowSelected}
          selectOptions={selectOptions}
          setSelectOptions={setSelectOptions}
          onResetMatchFieldOptions={onResetMatchFieldOptions}
          selectedModule={selectedModule}
          onResetRowSelected={() => setRowSelected({})}
        />
      );
    }
    return (
      <Step3
        rows={rows}
        orderedHeaders={orderedHeaders}
        getHeaders={getJsonHeaders}
        csvErrors={csvErrors}
        setStep={setActiveStep}
        forms={forms}
        formTypeId={selectedForm.value}
        selectedModule={selectedModule}
      />
    );
  };

  return (
    <>
      {organization && <UpgradeModal open={!organization?.features?.dashboard?.import?.enabled} />}
      <Box
        className={classes.container}
        style={
          !organization?.features?.dashboard?.import?.enabled
            ? {
                filter: 'blur(6px)',
                height: '70vh',
                overflow: 'hidden',
              }
            : {}
        }
      >
        <Typography variant="h5">Import </Typography>
        <Box marginTop="10px">
          <Box display="flex" alignItems="center" flexDirection="column" width="100%" maxWidth="1200px" margin="auto">
            <FormStepper color="#2E66FE" stepsArr={steps} active={activeStep} />
            {onRenderStep()}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FileImport;
