import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { cuGetConfig } from './CaseUpdateConfig';
import CaseUpdateSection from '../../../components/CaseUpdateSection';
import { FORM_TYPE_VERSION_2 } from '../../../config/config';
// import { updateForm } from '../../../services/unarmed';
// import WrapperSave from './WrapperSave';

import { CURenderer, componentsName } from './CaseUpdateRenderer';

export default function UpdateRenderer({
  components,
  version,
  id,
  customFieldsValues,
  setCustomFieldsValues,
  setIsRequestingData,
  setForm,
}) {
  console.log({components, customFieldsValues})
  // const [editComponents, setEditComponents] = useState({});
  const config = useMemo(
    () =>
      cuGetConfig({
        componentsFromServer: version !== FORM_TYPE_VERSION_2 ? components : [],
      }),
    [components, version, customFieldsValues]
  );


  // const onSubmitForm = (_id, pageIndex, componentsValues) => {
  //   const _pagesValues = components.map((page) => ({
  //     sections: [{ components: page?.sections?.[0]?.components.map((component) => component.value) }],
  //   }));

  //   _pagesValues[pageIndex] = { sections: [{ components: componentsValues.customFields }] };

  //   return updateForm(_id, { customFields: _pagesValues });
  // };

  // useEffect(() => {
  //   if (version === FORM_TYPE_VERSION_2) {
  //     setPagesValues(
  //       components.map((page) => ({
  //         sections: [{ components: page?.sections?.[0]?.components.map((component) => component.value) }],
  //       }))
  //     );
  //   }
  // }, [version, components]);

  if (version === FORM_TYPE_VERSION_2) {
    return (
      <>
        {components?.map((page, pageIndex) => {
          if (typeof page.active === 'boolean' && page.active === false) return null;

          if (typeof page.active === 'boolean' && page.active === false) return null;

          if (page?.sections?.[0]?.components?.length === 0) return null;

          const pageName = page?.name?.toLowerCase().replace(/ /g, '-');
          const itemId = `custom-section-${pageIndex}-${pageName}-${id}`;

          return (
            <Box style={{ background: '#fff', borderRadius: 6 }} boxShadow="0 3px 6px #00000005" marginBottom="24px" key={`${customFieldsValues}-${pageIndex}]`}>
              <CaseUpdateSection
                id={itemId}
                dataTestId={`case-updates-accordion-${itemId}`}
                name={page.name || `Custom Section ${pageIndex + 1}`}
                edit
                // edit={editComponents[itemId]}
                // onClickEditPage={() =>
                //   setEditComponents({
                //     ...editComponents,
                //     [itemId]: !editComponents[itemId],
                //   })
                // }
                data={page?.sections?.[0]?.components?.map((component) => ({
                  name: component?.data?.question || componentsName[component.type],
                  value: component.value,
                }))}
              >
                {/* <WrapperSave onSave={() => updateForm(id, { customFields: pagesValues })}> */}
                <CURenderer
                  id={id}
                  version={version}
                  setForm={setForm}
                  //onSubmitForm={(_id, values) => onSubmitForm(_id, pageIndex, values)}
                  setPagesValues={(values) => {
                    const _pagesValues = [...customFieldsValues];

                    console.log({_pagesValuesB: _pagesValues})

                    _pagesValues[pageIndex] = {
                      sections: [{ components: values }],
                    };
                    console.log({_pagesValues})

                    setCustomFieldsValues(_pagesValues);
                  }}
                  config={cuGetConfig({
                    componentsFromServer: page?.sections?.[0]?.components || [],
                  })}
                  setIsRequestingData={setIsRequestingData}
                  customFieldsValues={customFieldsValues?.[pageIndex]?.sections[0]?.components || []}
                />
                {/* </WrapperSave> */}
              </CaseUpdateSection>
            </Box>
          );
        })}
      </>
    );
  }

  if (version !== FORM_TYPE_VERSION_2 && components?.length === 0) return null;

  return (
    <CaseUpdateSection dataTestId="case-updates-accordion-custom" id="custom" name="Custom" edit data={[]}>
      <CURenderer
        config={config}
        setIsRequestingData={setIsRequestingData}
        id={id}
        customFieldsValues={components?.map((field) => field.value)}
        setForm={setForm}
      />
    </CaseUpdateSection>
  );
}

UpdateRenderer.propTypes = {
  id: PropTypes.number,
  version: PropTypes.number,
  setForm: PropTypes.func,
  components: PropTypes.array,
  customFieldsValues: PropTypes.array,
  setCustomFieldsValues: PropTypes.func,
  setIsRequestingData: PropTypes.func,
};
