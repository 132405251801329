function convertToCSV(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = ``;
    for (const index in array[i]) {
      if (line !== '') line += ',';

      const value = array[i][index];
      const isFlagUndefined = (index === 'Flag' && value === undefined);
      // Handle different value types appropriately
      if (value === null || value === undefined || isFlagUndefined) {
        if (isFlagUndefined) {
          line += 'false';
        } else {
          line += 'undefined';
        }
      } else if (typeof value === 'boolean') {
          line += value ? 'true' : 'false';
      } else if (typeof value === 'string' && value.includes(',')) {
        line += `"${value.replaceAll('"', '""')}"`;
      } else {
        line += value;
      }
    }
    str += `${line}\r\n`;
  }

  return str;
}

export const exportCSVFile = (headers, items, fileTitle, csv = false, downloadNow = true) => {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  const jsonObject = typeof items === 'string' ? JSON.stringify(items) : items;

  let csvFile = null;
  if (!csv) {
    csvFile = convertToCSV(jsonObject);
  } else {
    csvFile = csv;
  }
  const exportedFilenmae = `${fileTitle}.csv` || 'export.csv';

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.getElementById('downloadLink');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      if (downloadNow) {
        link.click();
      }
    }
  }
};
