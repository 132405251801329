import { Typography, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { getConfig } from './config/FormConfig';
import { Renderer } from './config/FormRenderer';

const useStyles = makeStyles(() => ({
  sectionTitle: {
    padding: 22,
    textAlign: 'center',
    fontSize: 16,
  },
  sectionWithoutTitle: {
    padding: 15,
    textAlign: 'center',
    fontSize: 16
  },
}));

export default function FormSection({
  name,
  components,
  getComponents,
  onSaveFormValues,
  getFormValue,
  organizationId,
  imagesPreview,
  setImagesPreview,
  withFileUpload = true,
  pageIndex,
  sectionIndex,
}) {
  const classes = useStyles();
  const config = useMemo(
    () =>
      getConfig({
        componentsFromServer: getComponents(components),
        imagesPreview: { pageIndex, sectionIndex, imagesPreview, setImagesPreview },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [components, getComponents]
  );

  return (
    <div>
      {name && name !== '' && <Typography className={name !== 'Section Name' ? classes.sectionTitle : classes.sectionWithoutTitle}>{name !== 'Section Name' ? name : ''}</Typography>}
      <Renderer
        sectionIndex={sectionIndex}
        config={config}
        withFileUpload={withFileUpload}
        onSaveFormValues={onSaveFormValues}
        orderComponents={false}
        getFormValue={getFormValue}
        organizationId={organizationId}
      />
    </div>
  );
}

FormSection.propTypes = {
  name: PropTypes.string.isRequired,
  pageIndex: PropTypes.number,
  sectionIndex: PropTypes.number,
  components: PropTypes.arrayOf(PropTypes.string).isRequired,
  getComponents: PropTypes.func.isRequired,
  onSaveFormValues: PropTypes.func.isRequired,
  getFormValue: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
  imagesPreview: PropTypes.arrayOf(PropTypes.string).isRequired,
  setImagesPreview: PropTypes.func.isRequired,
  withFileUpload: PropTypes.bool,
};
