import React from 'react';
import { Box, Typography, IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';

import AccordionWrap from './AccordionWrap';

const useStyles = makeStyles(() => ({
  name: {
    color: '#2E66FE',
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 8,
  },
  value: {
    fontSize: 18,
    color: '#575A66',
    fontWeight: 400,
  },
}));

/**
 * Component for rendering a section with the option to edit its contents.
 * @param {Object} props - The component props.
 * @param {string} props.name - The name of the section.
 * @param {boolean} props.edit - Boolean flag indicating whether the section is in edit mode.
 * @param {function} props.onClickEditPage - Function to handle click event for editing the section.
 * @param {Array<Object>} props.data - Array of data objects to display.
 * @param {ReactNode} props.children - Child components to render within the section.
 * @returns {JSX.Element} A React component.
 */
const CaseUpdateSection = ({ dataTestId, id, name, edit, onClickEditPage, data, children }) => {
  const classes = useStyles();

  return (
    <Box>
      <AccordionWrap
        id={id}
        dataTestId={dataTestId}
        header={
          <Box
            paddingX={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom="1px solid #e2e2e2"
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: '500',
                fontSize: 16,
                color: '#575A66',
                padding: '11px 0px',
              }}
            >
              {name}
            </Typography>
            {onClickEditPage && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (onClickEditPage) {
                    onClickEditPage(name);
                  }
                }}
                size="small"
              >
                <EditIcon htmlColor="#6d7385" />
              </IconButton>
            )}
          </Box>
        }
      >
        <Box style={{ paddingTop: '16px', paddingBottom: '32px' }}>
          {edit ? (
            children
          ) : (
            <>
              {data.map((item, itemKey) => (
                <Box key={itemKey} marginBottom={1}>
                  <Typography className={classes.name}>{item.name}</Typography>
                  <Typography className={classes.value}>{item.value || 'Not specified'}</Typography>
                </Box>
              ))}
            </>
          )}
        </Box>
      </AccordionWrap>
    </Box>
  );
};

CaseUpdateSection.propTypes = {
  dataTestId: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  edit: PropTypes.bool.isRequired,
  onClickEditPage: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
    })
  ).isRequired,
  children: PropTypes.node,
};

export default CaseUpdateSection;
