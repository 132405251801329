import React from 'react';
import { Box, Grid, Typography, Tooltip, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  formSection: {
    fontSize: 14,
    color: '#3f51b5',
    fontWeight: '500',
    margin: '20px 0',
  },
  label: {
    fontSize: 13,
    color: '#585858',
    fontWeight: 'normal',
    marginBottom: 8,
  },
  button: {
    background: ' #3B70FE',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#3B70FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  value: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16,
    fontWeight: 500,
    display: 'inline-block',
    width: '100%',
  },
}));

/**
 * CasePersonInfo component for displaying information about a case person.
 * @param {Object} props - Component props.
 * @param {Array} props.data - Array of objects containing label, value, and valueRaw properties.
 * @param {Function} props.onClickDelete - Function to handle delete action.
 * @returns {JSX.Element} CasePersonInfo component.
 */
const CasePersonInfo = ({ data, onClickDelete, onClickColumn, onClickColumnInNewWindow }) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Grid container spacing={2} alignItems="center">
        {data.map((item, itemKey) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={item.gridSize || 3}
            key={itemKey}
            style={onClickColumn ? { cursor: 'pointer' } : {}}
            onClick={onClickColumn}
          >
            <Typography className={classes.label} variant="body2">
              {item.label}
            </Typography>
            <Tooltip title={item.valueRaw}>
              <Typography className={classes.value} variant="subtitle">
                {item.value}
              </Typography>
            </Tooltip>
          </Grid>
        ))}
        <Grid item xs={1}>
          {onClickColumnInNewWindow && (
            <OpenInNewIcon
              htmlColor="#396dfe"
              style={{ cursor: 'pointer', marginRight: 4 }}
              onClick={onClickColumnInNewWindow}
            />
          )}
          <DeleteIcon htmlColor="#575a66" style={{ cursor: 'pointer' }} onClick={onClickDelete} />
        </Grid>
      </Grid>
    </Box>
  );
};

// PropTypes validation
CasePersonInfo.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      valueRaw: PropTypes.string,
      gridSize: PropTypes.number,
    })
  ),
  onClickDelete: PropTypes.func,
  onClickColumn: PropTypes.func,
  onClickColumnInNewWindow: PropTypes.func,
};

export default CasePersonInfo;
