import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import useOrganization from '../hooks/useOrganization';
import useInput from '../hooks/useInput';
import { OutLineInput } from '../pages/CaseDetails/UpdateCase';
import {
  getCivilianCases,
  getFilterByCase,
  getUsers,
  getUsersSearch,
  updateFilterConfigById,
} from '../services/unarmed';
import DatePickerMUI from './DatePickerMUi';
import SelectBox from './SelectBox';
import UserDropdown from './UsersDropdown';
import { Context } from '../Context';
import formatTxt from '../utils/formatText';

import MultiSelectBox from './MultiSelectBox';
import { CustomSwitch } from '../formEngine/components/WrapperComponent';
import { axiosCatchError } from '../utils';

export const activeColors = {
  color: 'rgba(0, 0, 0, 0.87)',
  border: '#408BEC',
  bg: 'transparent',
  focus: 'transparent',
};

const onSkipNullFromArray = (array) =>
  array?.filter((item) => {
    if (item) {
      return item;
    }

    return null;
  });

const useStyles = makeStyles(() => ({
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
}));

const CaseFilters = ({
  allegation,
  archivedOnly,
  assigned,
  code,
  discipline,
  disposition,
  filterConfigId,
  filtersApplied,
  filtersConfig,
  flaggedOnly,
  formType,
  from,
  investigator,
  name,
  onClearFilters,
  onClick,
  reporter,
  setAllegation,
  setAssigned,
  setDiscipline,
  setDisposition,
  setfiltersConfig,
  setFormType,
  setFrom,
  setIsFiltering,
  setTo,
  sortBy,
  source,
  status,
  subType,
  to,
  transferredOnly,
  email,
  phoneNumber,
  role,
  sortOrder,
}) => {
  const classes = useStyles();
  const { authUser, myUser } = useContext(Context);
  const [filterCases, setFilterCases] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAdvancedFilter, setShowAdvancedFilers] = useState(false);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersFiltered, setUsersFiltered] = useState([]);
  const [options, setOptions] = useState([]);
  const searchBox = useInput('');
  const [filteredCaseCount, setFilteredCaseCount] = useState(0);

  useEffect(() => {
    const onGetUsers = async () => {
      const filters = await getFilterByCase();

      setFilterCases(filters);
      const selectOptions = filters?.type?.slice(1)?.map((_formType) => ({
        text: _formType.toLowerCase(),
        value: _formType,
      }));
      setOptions(selectOptions);
      const { data } = await getUsers({});
      setUsers(data);
      if (typeof assigned === 'string' && assigned !== 'null') {
        const user = data.find((_user) => _user.email === assigned);
        setAssigned(user);
      }
      setUsersFiltered(data);
    };

    if (authUser) {
      onGetUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const onFilterUsers = () => {
    if (searchBox.value === '') {
      setUsersFiltered(users);
      return;
    }

    if (searchBox?.value?.length >= 3) {
      getUsersSearch(100, 'name', 'asc', searchBox.value).then((res) => {
        const _users = res?.data || [];

        setUsersFiltered(_users);
      });
    }
  };

  const getFilterSubtypeOptions = () => {
    const subTypeOptions = filterCases?.subtype;

    if (!subTypeOptions) return [];
    const stypeTypeOptionsKey = Object.keys(subTypeOptions);

    const subTypeOptionsArray = [];
    stypeTypeOptionsKey.forEach((key) => {
      const keyOptions = subTypeOptions[key];
      keyOptions.forEach((option) => {
        if (!subTypeOptionsArray.includes(option)) {
          subTypeOptionsArray.push(option);
        }
      });
    });

    if (formType && formType !== '0') {
      const formTypeOptions = subTypeOptions[formType] || [];

      // if (!formTypeOptions.length) {
      //  subType.setValue([]);
      // }

      // if (!formTypeOptions.includes(subType.value)) {
      //   subType.setValue([]);
      // }

      return formTypeOptions?.map((option) => ({
        text: option,
        value: option,
      }));
    }

    return subTypeOptionsArray?.map((option) => ({
      text: option,
      value: option,
    }));
  };

  const onFilter = async () => {
    if (from === 'Invalid date' || to === 'Invalid date') return;

    try {
      setIsFiltering(true);
      setLoading(true);

      const filterSaved = {
        from: from ? moment(from).format('YYYY-MM-DD') : null,
        to: to ? moment(to).format('YYYY-MM-DD') : null,
        email,
        name: name.value,
        phoneNumber,
        role,
        sortOrder,
        sortBy,
        flaggedOnly: flaggedOnly.value,
        assignedTo: assigned,
        type: formType,
        status: status.value,
        code: code.value,
        archivedOnly: archivedOnly.value,
        transferredOnly: transferredOnly.value,
      };
      //localStorage.setItem('filters', JSON.stringify(filterSaved));
      await updateFilterConfigById(filterConfigId, filterSaved);
      setfiltersConfig({ ...filtersConfig, ...filterSaved });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      axiosCatchError(error);
    }
  };

  const onFilterClear = async () => {
    if (from === 'Invalid date' || to === 'Invalid date') return;
    try {
      setIsFiltering(true);
      setLoading(true);

      const filterSaved = {
        from: null,
        to: null,
        email: '',
        name: '',
        phoneNumber,
        role,
        sortOrder,
        sortBy,
        flaggedOnly: false,
        assignedTo: '',
        type: '',
        status: '',
        code: '',
        archivedOnly: false,
        transferredOnly: false,
      };
      localStorage.setItem('filters', JSON.stringify(filterSaved));
      await updateFilterConfigById(filterConfigId, filterSaved);
      setfiltersConfig({ ...filtersConfig, ...filterSaved });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      axiosCatchError(error);
    }
  };

  const onSelectUser = (user) => {
    setAssigned(user);
    setOpen(false);
  };

  const onRemoveAssignedUser = () => {
    searchBox.setValue('');
    setAssigned(null);
  };

  const doneTypingInterval = 750;
  let interval;

  let assignedComponent;

  if (assigned !== 'null' && assigned) {
    assignedComponent = (
      <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
        <Avatar
          src={assigned?.photo?.url || assigned?.profile_img}
          style={{ marginRight: 10, width: 30, height: 30 }}
        />
        <Typography variant="subtitle2">{`${formatTxt(assigned?.firstName, 10)} ${formatTxt(
          assigned?.lastName,
          7
        )}`}</Typography>
        <CancelIcon htmlColor="#333" style={{ fontSize: 13, marginLeft: 20 }} onClick={onRemoveAssignedUser} />
      </Box>
    );
  } else if (assigned === 'null') {
    assignedComponent = (
      <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
        <Avatar src={assigned?.profile_img} style={{ marginRight: 10, width: 30, height: 30 }} />
        <Typography variant="subtitle2">Unassigned</Typography>
        <CancelIcon htmlColor="#333" style={{ fontSize: 13, marginLeft: 20 }} onClick={onRemoveAssignedUser} />
      </Box>
    );
  } else {
    assignedComponent = (
      <OutLineInput
        bordered
        placeholder="Select an Option"
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          'aria-label': 'weight',
        }}
        value={searchBox.value}
        onClick={() => setOpen(true)}
        labelWidth={0}
        onChange={(e) => {
          if (e.target.value.length <= 70) {
            searchBox.onChange(e);
          }
        }}
        onKeyUp={() => {
          clearTimeout(interval);

          interval = setTimeout(() => {
            onFilterUsers();
          }, doneTypingInterval);
        }}
        onKeyDown={() => {
          clearTimeout(interval);
        }}
      />
    );
  }

  const onClearFiltersAction = async () => {
    await onClearFilters();
    onFilterClear();
  };

  return (
    <Box
      boxShadow="0 4px 8px rgba(0,0,0,.1)"
      style={{ background: '#fff' }}
      padding="10px 20px"
      borderRadius="5px"
      marginTop="20px"
    >
      <Box display="flex">
        <Box width="100%" marginRight="20px">
          <Typography style={{ display: 'block', fontSize: 13, fontWeight: 500 }}>Type</Typography>
          <SelectBox
            id="type"
            options={{ obj: options?.length ? options : [{ text: 'No data', value: 'No data' }] }}
            value={formType || null}
            onChange={(e) => setFormType(e.target.value)}
            outlined
            withColors={filtersApplied.type ? activeColors : null}
          />
        </Box>
        <Box width="100%" marginRight="20px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 3,
            }}
          >
            Tracking Number
          </Typography>
          <OutLineInput
            bordered
            active={filtersApplied.code}
            placeholder="Tracking Number"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            value={code.value || ''}
            onChange={code.onChange}
            labelWidth={0}
          />
        </Box>
        <Box width="350px" marginRight="20px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 4,
            }}
          >
            Created Date
          </Typography>
          <Box display="flex" alignItems="flex-start" justifyContent="space-between">
            <DatePickerMUI
              active={filtersApplied.from}
              disableFuture
              label="From"
              date={from}
              setdate={setFrom}
              compareDate={to}
            />
            <span style={{ width: 15 }} />
            <DatePickerMUI
              active={filtersApplied.to}
              minDate={from}
              label="To"
              date={to}
              type="end"
              compareDate={from}
              setdate={setTo}
            />
          </Box>
        </Box>
        <Box width="100%" marginRight="20px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 3,
            }}
          >
            Name
          </Typography>
          <OutLineInput
            bordered
            active={filtersApplied.name}
            placeholder="Ex. John Doe"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            value={name.value || ''}
            onChange={name.onChange}
            labelWidth={0}
          />
        </Box>
        <Box width="100%" marginRight="20px" style={{ position: 'relative' }}>
          {open && (
            <UserDropdown
              usersFiltered={usersFiltered}
              onSelectUser={onSelectUser}
              showUnassigned={searchBox.value === ''}
              onClose={() => {
                setOpen(false);
                searchBox.setValue('');
                setUsersFiltered(users);
              }}
            />
          )}
          <Box style={{ position: 'absolute', zIndex: 2, width: '100%' }}>
            <Typography
              style={{
                display: 'block',
                fontSize: 13,
                fontWeight: 500,
                marginBottom: 3,
              }}
            >
              Assigned To
            </Typography>
            {assignedComponent}
          </Box>
        </Box>
        <Box width="100%">
          <Typography style={{ display: 'block', fontSize: 13, fontWeight: 500 }}>Status</Typography>
          <SelectBox
            id="status"
            options={onSkipNullFromArray(filterCases?.availableStatuses) || []}
            outlined
            value={status.value}
            onChange={status.onChange}
            withColors={filtersApplied.status ? activeColors : null}
          />
        </Box>
      </Box>
      {showAdvancedFilter && (
        <>
          <Box display="flex" width="100%" marginTop="20px">
            <Box width="100%" marginRight="20px">
              <Typography style={{ display: 'block', fontSize: 13, fontWeight: 500 }}>Subtype</Typography>
              <MultiSelectBox
                value={subType.value}
                outlined
                onChange={subType.onChange}
                id="subTypeMutliSelct"
                options={getFilterSubtypeOptions()?.map((item) => item.value)}
                active={filtersApplied.subtype}
              />
            </Box>
            <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px">
              <Typography style={{ display: 'block', fontSize: 13, fontWeight: 500 }}>Source</Typography>
              <MultiSelectBox
                value={source.value}
                outlined
                onChange={source.onChange}
                id="sourceMutliSelct"
                options={onSkipNullFromArray(filterCases?.source) || []}
                active={filtersApplied.source}
              />
            </Box>
            <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px">
              <Typography style={{ display: 'block', fontSize: 13, fontWeight: 500 }}>Reporter</Typography>
              <MultiSelectBox
                value={reporter.value}
                outlined
                onChange={reporter.onChange}
                id="reporterMutliSelct"
                options={onSkipNullFromArray(filterCases?.reporter) || []}
                active={filtersApplied.reporter}
              />
            </Box>
            <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px">
              <Typography style={{ display: 'block', fontSize: 13, fontWeight: 500 }}>Investigator</Typography>
              <MultiSelectBox
                value={investigator.value}
                outlined
                onChange={investigator.onChange}
                id="investigatorMutliSelct"
                options={onSkipNullFromArray(filterCases?.investigator) || []}
                active={filtersApplied.investigator}
              />
            </Box>
            <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px">
              <Typography
                style={{
                  display: 'block',
                  fontSize: 13,
                  fontWeight: 500,
                  marginBottom: 3,
                }}
              >
                Allegation
              </Typography>
              <MultiSelectBox
                value={allegation}
                outlined
                onChange={(e) => setAllegation(e.target.value)}
                id="alegationMutliSelct"
                options={onSkipNullFromArray(filterCases?.allegations) || []}
                active={filtersApplied.allegations}
              />
            </Box>
            <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px">
              <Typography
                style={{
                  display: 'block',
                  fontSize: 13,
                  fontWeight: 500,
                  marginBottom: 3,
                }}
              >
                Disposition
              </Typography>
              <MultiSelectBox
                value={disposition}
                outlined
                onChange={(e) => setDisposition(e.target.value)}
                id="dispositionMutliSelct"
                options={onSkipNullFromArray(filterCases?.dispositions) || []}
                active={filtersApplied.dispositions}
              />
            </Box>
            <Box minWidth="100px" maxWidth="200px" width="100%">
              <Typography
                style={{
                  display: 'block',
                  fontSize: 13,
                  fontWeight: 500,
                  marginBottom: 3,
                }}
              >
                Discipline
              </Typography>
              <MultiSelectBox
                value={discipline}
                outlined
                onChange={(e) => setDiscipline(e.target.value)}
                id="discliplineMutliSelct"
                options={onSkipNullFromArray(filterCases?.discipline) || []}
                active={filtersApplied.disciplines}
              />
            </Box>
          </Box>
          {myUser?.role === 'admin' ? (
            <Box display="flex" width="100%" marginTop="20px">
              <Box minWidth="160px" maxWidth="160px" width="100%">
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={<CustomSwitch checked={archivedOnly.value} onChange={archivedOnly.onChange} />}
                  labelPlacement="start"
                  label="Archived only"
                />
              </Box>
              <Box minWidth="170px" maxWidth="180px" width="100%">
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={<CustomSwitch checked={transferredOnly.value} onChange={transferredOnly.onChange} />}
                  labelPlacement="start"
                  label="Transferred only"
                />
              </Box>
              <Box minWidth="170px" maxWidth="180px" width="100%">
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={<CustomSwitch checked={flaggedOnly.value} onChange={flaggedOnly.onChange} />}
                  labelPlacement="start"
                  label="Flagged only"
                />
              </Box>
            </Box>
          ) : (
            <Box display="flex" width="100%" marginTop="20px">
              <Box minWidth="170px" maxWidth="180px" width="100%">
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={<CustomSwitch checked={flaggedOnly.value} onChange={flaggedOnly.onChange} />}
                  labelPlacement="start"
                  label="Flagged only"
                />
              </Box>
            </Box>
          )}
        </>
      )}
      <Divider style={{ display: 'block', width: '100%', margin: '15px 0' }} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" color="#1F63FF">
          <Typography
            style={{ fontSize: 13, fontWeight: '500', cursor: 'pointer' }}
            onClick={() => setShowAdvancedFilers(!showAdvancedFilter)}
          >
            Advance Filters
          </Typography>
          <Typography style={{ fontSize: 13, fontWeight: '500', margin: '0 10px' }}>|</Typography>
          <Typography style={{ fontSize: 13, fontWeight: '500', cursor: 'pointer' }} onClick={onClearFiltersAction}>
            Clear All Filters{' '}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button onClick={onClick} style={{ textTransform: 'capitalize', marginRight: 16 }}>
            Cancel
          </Button>
          <Button className={classes.button} onClick={onFilter}>
            {loading ? <CircularProgress color="#fff" size="25px" /> : 'Apply'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

CaseFilters.propTypes = {
  sortBy: PropTypes.string,
  onClick: PropTypes.func,
  formType: PropTypes.string,
  setFormType: PropTypes.func,
  code: PropTypes.string,
  assigned: PropTypes.string,
  status: PropTypes.string,
  name: PropTypes.string,
  setFrom: PropTypes.func,
  setTo: PropTypes.func,
  source: PropTypes.string,
  reporter: PropTypes.string,
  investigator: PropTypes.string,
  setAllegation: PropTypes.func,
  setDisposition: PropTypes.func,
  setDiscipline: PropTypes.func,
  from: PropTypes.string,
  to: PropTypes.string,
  onClearFilters: PropTypes.func,
  setAssigned: PropTypes.func,
  allegation: PropTypes.string,
  disposition: PropTypes.string,
  discipline: PropTypes.string,
  setIsFiltering: PropTypes.func,
  archivedOnly: PropTypes.bool,
  transferredOnly: PropTypes.bool,
  flaggedOnly: PropTypes.bool,
  subType: PropTypes.string,
  filtersConfig: PropTypes.object,
  filtersApplied: PropTypes.bool,
};

export default CaseFilters;
