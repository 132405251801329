import React, { useEffect, useState } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import useOrganization from '../../hooks/useOrganization';
import DashButton from '../../components/DashButton';
import SelectBox from '../../components/SelectBox';
import useInput from '../../hooks/useInput';
import { OutLineInput } from '../CaseDetails/UpdateCase';
import formatTxt from '../../utils/formatText';
import DescriptionIcon from '@material-ui/icons/Description';
import { getFormColor, getFormColorPlaceholder } from '../../utils';

const useStyles = makeStyles((theme) => ({
  tag: {
    width: 'fit-content',
    marginLeft: 10,
    borderRadius: 5,
    textTransform: 'capitalize',
    height: '100%',
  },
}));

export default function PageHeader({
  form,
  onSaveForm,
  loading,
  formInfo,
  isDefaultForm,
}) {
  const classes = useStyles();
  const formName = useInput('');
  const formDescription = useInput('');
  const name = useInput('');
  const type = useInput('');
  const [isEdit, setIsEdit] = useState(false);
  const { organization } = useOrganization();
  const [formTypes, setFormTypes] = useState({})
  const [currentFormType, setCurrentFormType] = useState('');

  useEffect(() => {
    if (formInfo) {
      formName.setValue(formInfo.name);
      formDescription.setValue(formInfo.description);
      name.setValue(formInfo.adminName);
      type.setValue(formInfo.type);
      setCurrentFormType(formInfo.type);
    }

    if (organization) {
      const types = {
        COMPLIMENT: "Compliment",
        COMPLAINT: "Complaint",
        REVIEW: "Review",
        RECOMMENDATION: "Recommendation",
        ...organization.customFormTypes,
      };
      setFormTypes(types);

      if (!currentFormType) {
        setCurrentFormType(Object.keys(types)[0]);
      }
    }
  }, [formInfo, organization]);

  const handleTypeChange = (event) => {
    const selectedType = event?.target?.value || event;
    type.setValue(selectedType.toUpperCase());
    setCurrentFormType(selectedType);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginBottom="24px"
    >
      <Box>
        <Box display="flex" marginBottom={1} alignItems="center">
          {isEdit ? (
            <>
              <Box width={250}>
                <OutLineInput
                  bordered
                  placeholder="Ex. Form Name"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  value={name.value || ''}
                  onChange={name.onChange}
                  labelWidth={0}
                />
              </Box>
            </>
          ) : (
            <Typography style={{ fontSize: 24, fontWeight: '500' }}>
              {formatTxt(name.value || form?.adminName || '(Name)', 25)}
            </Typography>
          )}
        </Box>
        <Box display="flex" marginBottom={1} alignItems="center">
          {isEdit ? (
            <>
              <Box width={250}>
                <OutLineInput
                  bordered
                  placeholder="Ex. Diplay Name"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  value={formName.value || ''}
                  onChange={formName.onChange}
                  labelWidth={0}
                />
              </Box>
            </>
          ) : (
            <Typography style={{ fontSize: 24, fontWeight: '500' }}>
              {formatTxt(formName.value || form?.name || '(Form_name)', 25)}
            </Typography>
          )}
          {!isDefaultForm && (
            <CreateIcon
              style={{ marginLeft: 12, cursor: 'pointer' }}
              htmlColor="#6D7385"
              onClick={() => setIsEdit(!isEdit)}
            />
          )}
        </Box>
        <Box display="flex" marginBottom={1} alignItems="center">
          {isEdit ? (
            <Box width={250}>
              <OutLineInput
                bordered
                placeholder="Ex. Form Description"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                value={formDescription.value || ''}
                onChange={formDescription.onChange}
                labelWidth={0}
              />
            </Box>
          ) : (
            <Typography style={{ fontSize: 14 }}>
              {formDescription.value}
            </Typography>
          )}
        </Box>
        <Box
          className={classes.tag}
          style={{
            background: getFormColorPlaceholder(currentFormType),
            marginLeft: 0,
          }}
        >
        {!isDefaultForm ? (
          <SelectBox
            options={{
              obj: Object.entries(formTypes || {}).map(([key, displayText]) => ({
                value: key,
                text: displayText,
              })),
            }}
            value={currentFormType}
            onChange={(e) => handleTypeChange(e)}
            allowPlaceHolder={false}
            withColors={{
              color: getFormColor(currentFormType),
            }}
            style={{ marginLeft: -2 }}
          />
          ) : (
            <Box display="flex" alignItems="center">
            <DescriptionIcon
              htmlColor="#6D7385"
              style={{ marginRight: 6, fontSize: 15 }}
            />
            <Typography
              style={{
                color: '#6D7385',
                fontSize: 12,
                textTransform: 'capitalize',
              }}
            >
              {formInfo?.typeDisplayText}
            </Typography>
          </Box>
          )}
        </Box>
      </Box>
      {!formInfo?.defaultForm && (
        <Box>
          <DashButton
            onClick={() => {
              setIsEdit(false);
              onSaveForm({
                name: formName.value,
                description: formDescription.value,
                adminName: name.value,
                type: type.value,
              });
            }}
          >
            {loading === 'pending' ? (
              <CircularProgress color="#fff" size="20px" />
            ) : (
              'Save'
            )}{' '}
          </DashButton>
        </Box>
      )}
    </Box>
  );
}
