import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { Link } from 'react-router-dom';
import UpgradeModal from '../../components/UpgradeModal';
import { Context } from '../../Context';
import useOrganization from '../../hooks/useOrganization';
import TabsModules from '../../components/TabsModules';
import ReportsTableTab from './ReportsTableTab';
import DashboardsTableTab from './DashboardsTableTab';
import ExploreProfile from './ExploreProfile/ExploreProfile';
import { useTabsUtil } from '../../hooks/useTabs';
import { TYPE_USER_TABS } from '../../utils/constants';
import DashboardProfile from './DashboardProfile';
import { getDashboardbyId } from '../../services/unarmed';

export default function Insights() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { authUser, setActive, myUser } = React.useContext(Context);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sort, setSort] = useState('desc');
  const { organization } = useOrganization();
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [dashboard, setDashboard] = useState(null);

  useEffect(() => {
    setActive('/insights');
  }, []);

  const {
    activeTab,
    setActiveTab,
    tabSelected,
    setTabSelected,
    onGetUserTabs,
    userTabs,
    updateNameTab,
    setUserTabs,
    handleSaveTab,
    handleTabSelection,
    nextPosition,
    setShowReportTable,
    handleTabsReorder,
    handleTabSelectionAction,
    deleteSelectedTab,
    activeTabName,
    setActiveTabName,
  } = useTabsUtil({ moduleName: 'INSIGHT' });

  useEffect(() => {
    const fetchDashboardDetails = async (dashboardId) => {
      if (!dashboardId) return;
      try {
        setLoading(true);
        const dashboardData = await getDashboardbyId(dashboardId);
        setDashboard(dashboardData.data);
      } catch (error) {
        console.error('Error fetching dashboard details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (activeTab !== TYPE_USER_TABS.DASHBOARD) {
      setDashboard(null);
      return;
    }

    if (activeTab === TYPE_USER_TABS.DASHBOARD && tabSelected?.displayObjectId) {
      fetchDashboardDetails(tabSelected.displayObjectId);
    }
  }, [activeTab, tabSelected]);

  const handleReportSelection = (reportId) => {
    setTabSelected((prevTab) => ({
      ...prevTab,
      displayObjectId: reportId,
    }));

    setActiveTab(TYPE_USER_TABS.EXPLORE);
  };

  return (
    <>
      {organization && <UpgradeModal open={!organization?.features?.dashboard?.insights?.enabled} />}
      <Box className={`container ${!organization?.features?.dashboard?.insights?.enabled ? 'blur' : ''}`}>
        <Box>
          <Typography variant="h5">Insights</Typography>
        </Box>
        <div className="right-aligned-row">
          <HelpIcon htmlColor="#2E66FE" fontSize="inherit" style={{ marginRight: 8 }} />
          <Typography className="description-text" style={{ fontSize: '12px' }}>
            Help? See our{' '}
            <Link to="/insights-docs">
              <span className="documentation-link">Documentation</span>
            </Link>
          </Typography>
        </div>

        <TabsModules
          setTabs={setUserTabs}
          updateNameTab={updateNameTab}
          tabSelected={tabSelected}
          activeTabName={activeTabName}
          activeTab={activeTab}
          setTabSelected={setTabSelected}
          setActiveTab={setActiveTab}
          setActiveTabName={setActiveTabName}
          tabs={userTabs}
          navigateToDataPage={() => {}}
          saveTab={handleSaveTab}
          onTabSelect={handleTabSelection}
          nextPosition={nextPosition}
          saveTabPositions={handleTabsReorder}
          onTabAction={handleTabSelectionAction}
          deleteTab={deleteSelectedTab}
          moduleName={'INSIGHT'}
        />

        {activeTab === TYPE_USER_TABS.REPORTS && (
          <ReportsTableTab
            selectedReportId={selectedReportId}
            setSelectedReportId={setSelectedReportId}
            ReportId={tabSelected?.displayObjectId}
            onReportSelect={handleReportSelection}
            setTabSelected={setTabSelected}
            tabSelected={tabSelected}
            setActiveTab={setActiveTab}
            setActiveTabName={setActiveTabName}
          />
        )}

        {activeTab === TYPE_USER_TABS.EXPLORE && (
          <Box marginTop="30px">
            <Box className="content-container">
              <ExploreProfile selectedReportId={selectedReportId} />
            </Box>
          </Box>
        )}

        {activeTab === TYPE_USER_TABS.DASHBOARD && dashboard && (
          <DashboardProfile dashboardId={tabSelected?.displayObjectId} dashboard={dashboard} />
        )}

        {activeTab === TYPE_USER_TABS.DASHBOARDS_LIST && (
          <DashboardsTableTab
            handleSaveTab={handleSaveTab}
            setActiveTab={setActiveTab}
            setUserTabs={setUserTabs}
            userTabs={userTabs}
            setActiveTabName={setActiveTabName}
            setTabSelected={setTabSelected}
          />
        )}

        {loading && (
          <Box display="flex" justifyContent="center" marginTop={2}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
}
