import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, Router, BrowserRouter, Redirect } from 'react-router-dom';
import Layout from '../components/Layout';
import { Context } from '../Context';
import Activity from '../pages/Activity/Activity';
import Allegations from '../pages/Allegations/Allegations';
import Login from '../pages/Auth/Login/Login';
import CaseDetails from '../pages/CaseDetails/CaseDetails';
import CasePdf from '../pages/CaseDetails/CasePdf';
import Cases from '../pages/Cases/Cases';
import Data from '../pages/Data/Data';
import FileImport from '../pages/FileImport/FileImport';
import SuccessImported from '../pages/FileImport/SuccessImported';
import FormEngine from '../pages/FormEngine/FormEngine';
import Forms from '../pages/Forms/Forms';
import Home from '../pages/Home/Home';
import Mfa from '../pages/Mfa/Mfa';
import OfficerProfile from '../pages/Officer/OfficerProfile';
import OfficersList from '../pages/Officers/OfficersTable';
import Permissions from '../pages/Permissions/Permissions';
import Profile from '../pages/Profile/Profile';
import ReportProfile from '../pages/ReportProfile/ReportProfile';
import ExploreProfile from '../pages/Insights/ExploreProfile/ExploreProfile';
import DashboardProfile from '../pages/Insights/DashboardProfile';
import Usage from '../pages/Usage/Usage';
import User from '../pages/User/User';
import Users from '../pages/Users/Users';
import NotFound from '../pages/NotFound';
import Insights from '../pages/Insights/Insights';

import history from './history';
import FormProfile from '../pages/FormProfile/FormProfile';
import Signup from '../pages/Auth/Signup/Signup';
import ResetPassword from '../pages/Auth/ResetPassword/ResetPassword';
import UpdateAlert from '../components/UpdateAlert';
import DataDocumentation from '../pages/DataDocumentation/DataDocumentation';
import InsightsDocumentation from '../pages/Insights/InsightsDocumentation';
import ResetPasswordById from '../pages/Auth/ResetPasswordById/ResetPasswordById';
import FAQ from '../pages/FAQ/FAQ';
import Settings from '../pages/Settings/Settings';
import FormDashboard from '../pages/FormDashboard/FormDashboard';
import SuccessSumitted from '../pages/FormDashboard/SuccessScreen';
import Projects from '../pages/Projects/Projects';
import ProjectDetails from '../pages/Projects/ProjectDetails';
import Notifications from '../pages/Notifications/Notifications';
import Contacts from '../pages/Contacts/Contacts';
import ContactProfile from '../pages/Contacts/ContactProfile';
import Social from '../pages/Social/Social';

function NoMatch() {
  return <NotFound />;
}

function PrivateRoute({ auth, showMfa, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (auth && showMfa) {
          return (
            <Redirect
              to={{
                pathname: '/mfa',
              }}
            />
          );
        }
        if (auth) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

const generalRoutes = {
  allegations: '/allegations',
  cases: '/cases',
  contacts: '/contacts',
  forms: '/forms',
  import: '/import',
  insights: '/insights',
  officers: '/officers',
  projects: '/projects',
  users: '/users',
};

const casesRoutes = [
  { path: '/cases', Component: Cases },
  { path: '/case/:id', Component: CaseDetails },
  { path: '/case-exported/:id', Component: CasePdf },
];
const contactsRoutes = [
  { path: '/contacts', Component: Contacts },
  {
    path: '/contacts/:id',
    Component: ContactProfile,
  },
];
const officerRoutes = [
  { path: '/officer/:id', Component: OfficerProfile },
  { path: '/officers', Component: OfficersList },
];

const adminRoutes = [
  { path: '/usage', Component: Usage },
  { path: '/activity', Component: Activity },
  { path: '/user/:id', Component: User },
  { path: '/invitation/:id', Component: User },
  { path: '/import', Component: FileImport },
  { path: '/permissions', Component: Permissions },
];

const AppRouter = ({ auth, showMfa, hasEnrollFactor }) => {
  const { organization, myUser, requireRefresh } = useContext(Context);
  const [defaultRoute, setDefaultRoute] = useState('/');
  const [stateUpdate, setStateUpdate] = useState(false);
  function findRouteWithPermission(permissions, generalRoutes) {
    for (const permission in permissions) {
      if (permissions[permission] === 2 && generalRoutes.hasOwnProperty(permission)) {
        return generalRoutes[permission];
      }
    }
    return null;
  }

  useEffect(() => {
    if (organization?.permissions?.[myUser?.role]?.cases === 0) {
      const result = findRouteWithPermission(organization?.permissions?.[myUser?.role], generalRoutes);
      setDefaultRoute(result);
      setStateUpdate(true);
    } else if (organization?.permissions?.[myUser?.role]?.cases === 2) {
      setStateUpdate(true);
    }
  }, [organization, myUser]);

  return (
    <>
      {requireRefresh && <UpdateAlert />}
      <BrowserRouter>
        <Router history={history}>
          <Switch>
            {/*<PrivateRoute path="/" exact auth={auth} showMfa={showMfa}>
              {(organization?.permissions?.[myUser?.role]?.dashboard && (
                <Layout>
                  <Home />
                </Layout>
              )) || (
                <Redirect
                  to={{
                    pathname: '/cases',
                  }}
                />
              )}
            </PrivateRoute>*/}
            <PrivateRoute path="/" exact auth={auth} showMfa={showMfa}>
              {(stateUpdate && organization?.permissions?.[myUser?.role]?.cases && (
                <Layout>
                  <Home />
                </Layout>
              )) || (
                <Redirect
                  to={{
                    pathname: defaultRoute,
                  }}
                />
              )}
            </PrivateRoute>

            {casesRoutes.map((route, index) => (
              <PrivateRoute path={route.path} exact auth={auth} showMfa={showMfa} key={index}>
                {organization?.permissions?.[myUser?.role]?.cases ? (
                  <Layout>
                    <route.Component />
                  </Layout>
                ) : (
                  <NoMatch />
                )}
              </PrivateRoute>
            ))}
            {contactsRoutes.map((route, index) => (
              <PrivateRoute path={route.path} exact auth={auth} showMfa={showMfa} key={index}>
                {organization?.permissions?.[myUser?.role]?.contacts ? (
                  <Layout>
                    <route.Component />
                  </Layout>
                ) : (
                  <NoMatch />
                )}
              </PrivateRoute>
            ))}
            {officerRoutes.map((route, index) => (
              <PrivateRoute path={route.path} exact auth={auth} showMfa={showMfa} key={index}>
                {organization?.permissions?.[myUser?.role]?.officers ? (
                  <Layout>
                    <route.Component />
                  </Layout>
                ) : (
                  <NoMatch />
                )}
              </PrivateRoute>
            ))}
            {adminRoutes.map((route, index) => (
              <PrivateRoute path={route.path} exact auth={auth} showMfa={showMfa} key={index}>
                {myUser?.role === 'admin' ? (
                  <Layout>
                    <route.Component />
                  </Layout>
                ) : (
                  <NoMatch />
                )}
              </PrivateRoute>
            ))}

            <PrivateRoute path="/users" exact auth={auth} showMfa={showMfa}>
              {organization?.permissions?.[myUser?.role]?.users ? (
                <Layout>
                  <Users />
                </Layout>
              ) : (
                <NoMatch />
              )}
            </PrivateRoute>

            <PrivateRoute path="/allegations" exact auth={auth} showMfa={showMfa}>
              {organization?.permissions?.[myUser?.role]?.allegations ? (
                <Layout>
                  <Allegations />
                </Layout>
              ) : (
                <NoMatch />
              )}
            </PrivateRoute>

            <PrivateRoute path="/social" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <Social />
              </Layout>
            </PrivateRoute>

            <PrivateRoute path="/forms" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <Forms />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/form/:id" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <FormProfile />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/profile" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <Profile />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/success-imported" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <SuccessImported />
              </Layout>
            </PrivateRoute>

            <PrivateRoute path="/data" exact auth={auth} showMfa={showMfa}>
              <Layout allowScroll={false}>
                <Data />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/data/:id" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <ReportProfile />
              </Layout>
            </PrivateRoute>

            <PrivateRoute path="/insights" exact auth={auth} showMfa={showMfa}>
              <Layout allowScroll={false}>
                <Insights />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/insights/:id" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <ExploreProfile />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/insights/dashboards" exact auth={auth} showMfa={showMfa}>
              <Layout allowScroll={false}>
                <Insights />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/insights/dashboards/:id" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <DashboardProfile />
              </Layout>
            </PrivateRoute>

            <PrivateRoute path="/settings" exact auth={auth} showMfa={showMfa}>
              <Layout allowScroll={false}>
                <Settings />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/form-engine" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <FormEngine />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/data-docs" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <DataDocumentation />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/insights-docs" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <InsightsDocumentation />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/faq" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <FAQ />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/projects" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <Projects />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/notifications" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <Notifications />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/project/:id" exact auth={auth} showMfa={showMfa}>
              <Layout>
                <ProjectDetails />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/form-dashboard/:id" exact auth={auth} showMfa={showMfa}>
              <FormDashboard />
            </PrivateRoute>
            <PrivateRoute path="/successForm/:id" exact auth={auth} showMfa={showMfa}>
              <SuccessSumitted />
            </PrivateRoute>

            {auth && (
              <Route path="/mfa" exact>
                <Mfa shouldShowSkip={showMfa || !hasEnrollFactor} />
              </Route>
            )}

            {auth && window.location.pathname.includes('/mfa') && (
              <Redirect
                to={{
                  pathname: '/mfa',
                }}
              />
            )}

            {!auth && (
              <Route path="/login" exact>
                <Login />
              </Route>
            )}

            <Route path="/signup" exact>
              <Signup />
            </Route>

            {!auth && (
              <Route path="/reset-password" exact>
                <ResetPassword />
              </Route>
            )}
            {!auth && (
              <Route path="/password/reset" exact>
                <ResetPasswordById />
              </Route>
            )}

            {!auth && window.location.pathname.includes('/mfa') && (
              <Redirect
                to={{
                  pathname: '/login',
                }}
              />
            )}

            {auth && window.location.pathname.includes('/login') && !showMfa && (
              <Redirect
                to={{
                  pathname: '/',
                }}
              />
            )}

            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Router>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
