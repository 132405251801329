import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { updateForm } from '../../../../services/unarmed';
import WrapperSave from '../WrapperSave';
import PreviewLabel from './PreviewLabel';
import { FORM_TYPE_VERSION_2 } from '../../../../config/config';

/**
 * Custom Component for Multiple Choice
 * @param {object} props - Component props
 * @param {object} props.data - Component data
 * @param {string} props.version - Form version
 * @param {Function} props.setPagesValues - Function to update page values
 * @param {number} props.componentIndex - Component index
 * @param {string} props.id - Component ID
 * @param {string[]} props.value - Component value
 * @param {string[]} props.customFieldsValues - Custom field values
 * @param {Function} props.setForm - Function to set form state
 * @param {Function} props.setIsRequestingData - Function to set requesting data state
 * @returns {JSX.Element} CUMultipleChoice component
 */
export default function CUMultipleChoice({
  data,
  version,
  setPagesValues,
  componentIndex,
  id,
  // value,
  customFieldsValues,
  setForm,
  setIsRequestingData,
  onSubmitForm,
  withSave = true,
}) {
  const value = customFieldsValues[componentIndex];
  const [options, setOptions] = useState(value || []);

  /**
   * Handles save operation
   */
  const onSave = async () => {
    setIsRequestingData(true);
    try {
      const arrValue = Array.from(customFieldsValues);
      arrValue[componentIndex] = options;
      const json = {
        customFields: arrValue,
      };
      const res = version === FORM_TYPE_VERSION_2 ? await onSubmitForm(id, json) : await updateForm(id, json);
      setForm((prevState) => ({ ...prevState, ...res.data }));
      toast.success('Case Updates have been updated');
      setIsRequestingData(false);
    } catch (error) {
      toast.error(error.message);
      setIsRequestingData(false);
    }
  };

  useEffect(() => {
    if (version === FORM_TYPE_VERSION_2) {
      const arrValue = Array.from(customFieldsValues);
      arrValue[componentIndex] = options;

      setPagesValues(arrValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const render = (
    <>
      <PreviewLabel question={data?.question} required={data?.required} />
      {data?.options?.map((option, index) => (
        <Box display="flex" alignItems="center" key={index} marginBottom="10px">
          <Checkbox
            color="default"
            checked={options.includes(option)}
            onChange={() => {
              const optionsCopy = Array.from(options);
              if (options.includes(option)) {
                const idx = options.indexOf(option);
                optionsCopy.splice(idx, 1);
              } else {
                optionsCopy.push(option);
              }
              setOptions(optionsCopy);
            }}
            inputProps={{ 'aria-label': 'checkbox with default color' }}
            style={{ padding: 0, marginRight: 5 }}
          />
          <Typography style={{ color: '#00000061' }}>{option}</Typography>
        </Box>
      ))}
    </>
  );

  if (version === FORM_TYPE_VERSION_2 && !withSave)
    return (
      <Box
        style={{ background: '#fff', borderRadius: 5 }}
        margin="20px 20px 0 0"
        width="100%"
        maxWidth="500px"
        display="flex"
        flexDirection="column"
      >
        <Box flexGrow={1}>{render}</Box>
      </Box>
    );

  return (
    <WrapperSave padding={version === FORM_TYPE_VERSION_2 ? '0px' : '20px'} onSave={onSave}>
      {render}
    </WrapperSave>
  );
}

CUMultipleChoice.propTypes = {
  data: PropTypes.object.isRequired,
  version: PropTypes.string.isRequired,
  setPagesValues: PropTypes.func.isRequired,
  componentIndex: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  // value: PropTypes.array.isRequired,
  customFieldsValues: PropTypes.array.isRequired,
  setForm: PropTypes.func.isRequired,
  setIsRequestingData: PropTypes.func.isRequired,
  withSave: PropTypes.bool,
  onSubmitForm: PropTypes.func.isRequired,
};
