import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  Fade,
  makeStyles,
  Modal,
  TableCell,
  TableRow,
  Typography,
  TextField,
  Button,
  Grid,
  Input,
  InputLabel,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { Context } from '../../Context';
import {
  createOfficer,
  deleteOfficer,
  getOfficers,
  editMultipleOfficers,
  updateViewsById,
} from '../../services/unarmed';
import useInput from '../../hooks/useInput';
import Flagged from '../../assets/flagged.png';
import UpgradeModal from '../../components/UpgradeModal';
import useOrganization from '../../hooks/useOrganization';
import { logEvent } from '../../services/firebase';
import MultiSelectBox from '../../components/MultiSelectBox';
import { genderOptions, raceEthnicityOptions } from '../../utils/options';
import useFiltersConfig from '../../hooks/useFiltersConfig';
import { useTabsUtil } from '../../hooks/useTabs';
import { TYPE_USER_TABS } from '../../utils/constants';
import TabsModules from '../../components/TabsModules';
import { ViewTabOfficers } from './ViewTabOfficers';
import ArrayTooltip from '../../components/ArrayTooltip';

export const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      autoComplete="off"
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      style={{ width: '100%' }}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    width: '100%',

    '& .makeStyles-root-51': {
      boxShadow: 'unset !important',
    },
  },
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    '&:disabled': {
      background: '#1f63ff61',
      color: '#fff',
    },
  },
  label: {
    color: '#585858',
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#1F63FF',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 500,
    outline: 0,
  },
}));

const OfficersList = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingDeleteOfficer, setLoadingDeleteOfficer] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [checked, setChecked] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const { authUser, setActive } = useContext(Context);
  const [officers, setOfficers] = useState(null);
  const [sortBy, setSortBy] = useState('badgeNumber');
  const [sort, setSort] = useState('desc');
  const badge = useInput('');
  const name = useInput('');
  const { organization } = useOrganization();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const firstName = useInput('', isSubmitting);
  const lastName = useInput('', isSubmitting);
  const badgeNumber = useInput('', isSubmitting);
  const flaggedOnly = useInput(false, false, false, true);
  const email = useInput('');
  const phone = useInput('');
  const [loadingCreateOfficer, setLoadingCreateOfficer] = useState(false);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const history = useHistory();

  const [gender, setGender] = useState([]);
  const [race, setRace] = useState([]);

  const baseColumns = {
    flag: true,
    badgeSerialNumber: true,
    officerName: false,
    officerPhone: true,
    createdDate: false,
  };
  const [loadingEditOfficers, setLoadingEditOfficers] = useState(false);
  const [view, setView] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState(baseColumns);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredColumns = Object.keys(visibleColumns).filter((column) =>
    column.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const showColumns = filteredColumns.filter((column) => visibleColumns[column]);
  const hideColumns = filteredColumns.filter((column) => !visibleColumns[column]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = open ? 'column-popover' : undefined;

  const {
    filtersConfig,
    filterCount,
    filtersLoaded,
    filtersApplied,
    resetFilterCount,
    setFiltersLoaded,
    fetchFiltersConfig,
  } = useFiltersConfig('officers', ['includeTotalCases']);

  useEffect(() => {
    setActive('/officers');
  }, []);

  const {
    activeTab,
    setActiveTab,
    tabSelected,
    setTabSelected,
    userTabs,
    updateNameTab,
    setUserTabs,
    handleSaveTab,
    handleTabSelection,
    nextPosition,
    handleTabsReorder,
    handleTabSelectionAction,
    deleteSelectedTab,
    activeTabName,
    setActiveTabName,
  } = useTabsUtil({ moduleName: 'OFFICERS' });

  const gotToOfficerProfile = (e, row, handleClick) => {
    const go = handleClick(e, row._id);
    if (go) {
      history.push(`/officer/${row._id}`);
      setActive('/officers');
      logEvent('enter_officer_details');
    }
  };

  const handleToggleColumn = async (viewId, columnName) => {
    try {
      const updatedColumns = {
        ...visibleColumns,
        [columnName]: !visibleColumns[columnName],
      };

      const response = await updateViewsById(viewId, { columns: updatedColumns });

      if (response && response.status === 200 && response.view) {
        setVisibleColumns(response.view.columns);
      } else {
        console.error('Failed to update columns:', response);
      }
    } catch (error) {
      console.error('Error updating column visibility:', error);
    }
  };

  const formatHeadersText = (str) => {
    const result = str.replace(/([A-Z])/g, ' $1');

    return result.replace(/^./, (firstChar) => firstChar.toUpperCase()).trim();
  };

  const onEditMultipleOfficers = async () => {
    let ids = '';
    const selectedOfficers = officers.data.filter((officer) => selected.includes(officer._id));
    const allAreFlagged = selectedOfficers.every((officer) => officer.flagged);

    for (const id of selected) {
      const index = selected.indexOf(id);
      if (index + 1 === selected.length) {
        ids += `ids[]=${id}`;
      } else {
        ids += `ids[]=${id}&`;
      }
    }

    try {
      setLoadingEditOfficers(true);
      await editMultipleOfficers(ids);

      setOfficers((prevOfficers) => {
        const updatedData = prevOfficers.data.map((officer) => {
          if (selected.includes(officer._id)) {
            return {
              ...officer,
              flagged: !allAreFlagged,
            };
          }
          return officer;
        });

        return {
          ...prevOfficers,
          data: updatedData,
        };
      });

      setLoadingEditOfficers(false);
      setSelected([]);
      toast.success(allAreFlagged ? 'Successfully unflagged officers' : 'Successfully flagged officers');
    } catch (error) {
      setLoadingEditOfficers(false);
      toast.error(error.message);
    }
  };

  const getDefaultOfficers = async () => {
    try {
      setLoading(true);
      const { data, headers } = await getOfficers(
        filtersConfig ? filtersConfig._id : null,
        true,
        page,
        rowsPerPage,
        sort,
        sortBy,
        false
      );
      console.log('DATA', {data})
      setOfficers({ data, headers });
      fetchFiltersConfig();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onCreateOfficer = async () => {
    setIsSubmitting(true);
    if (!firstName.value || !lastName.value || !badgeNumber.value) {
      return;
    }

    try {
      setLoadingCreateOfficer(true);
      await createOfficer(badgeNumber.value, firstName.value, lastName.value, email.value, phone.value, gender, race);

      getDefaultOfficers();

      toast.success(`Officer created Successfully`);
      setLoadingCreateOfficer(false);
      badgeNumber.setValue('');
      firstName.setValue('');
      lastName.setValue('');
      badgeNumber.setError('');
      email.setValue('');
      email.setError('');
      phone.setValue('');
      setGender([]);
      setRace([]);
      phone.setError('');
      firstName.setError('');
      lastName.setError('');
      setIsSubmitting(false);
      setOpen(false);
    } catch (error) {
      setLoadingCreateOfficer(false);

      toast.error(error.response?.data?.message || error?.response?.data?.error?.details?.[0].message);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const onClearAllFilters = () => {
    name.setValue(null);
    badge.setValue(null);
    flaggedOnly.setValue(false);
    resetFilterCount();
    toast.success('Successfully cleared filters!');
  };

  const onDeleteOfficer = async () => {
    let ids = '';
    for (const id of selected) {
      const index = selected.indexOf(id);
      ids += `ids[]=${id}${index + 1 === selected.length ? '' : '&'}`;
    }

    try {
      const response = await deleteOfficer(ids);
      const { deleted, notDeleted } = response.data;

      if (deleted.length === 0) {
        toast.error('No officers were deleted. All are assigned to forms.');
      } else {
        toast.success(`${deleted.length} officer(s) deleted successfully.`);
      }

      if (notDeleted.length > 0) {
        toast.warn(
          `${notDeleted.length} officer(s) could not be deleted. Assigned forms: ${notDeleted
            .map(
              (officer) =>
                `${officer.firstName} ${officer.lastName} (${officer.badgeNumber}) - ${officer.formsCount} forms`
            )
            .join(', ')}.`
        );
      }
      const { data, headers } = await getOfficers({
        page,
        limit: rowsPerPage,
        sort,
        sortBy,
        isFiltering,
        name: name.value,
        badge: badge.value,
        flaggedOnly: flaggedOnly.value,
      });
      setOfficers({ data, headers });

      setLoadingDeleteOfficer(false);
      setSelected([]);
      setOpenDelete(false);
    } catch (error) {
      setLoadingDeleteOfficer(false);
      toast.error(error.response?.data?.message || 'Failed to delete officers');
    }
  };

  const getVisibleColumns = () =>
    Object.keys(visibleColumns)
      .filter((column) => visibleColumns[column])
      .map((column) => formatHeadersText(column));

  const renderRow = (row, index, handleClick) => {
    const isItemSelected = isSelected(row._id);
    const labelId = `enhanced-table-checkbox-${index}`;

    return (
      <TableRow
        hover
        aria-checked={isItemSelected}
        selected={isItemSelected}
        role="checkbox"
        tabIndex={-1}
        key={index}
        className={classes.row}
        style={{ cursor: 'pointer', height: 50 }}
      >
        <TableCell padding="checkbox" onClick={(e) => gotToOfficerProfile(e, row, handleClick)}>
          <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color="primary" />
        </TableCell>
        {visibleColumns?.flag && (
          <TableCell component="th" scope="row" align="left">
            {row?.flagged && <img src={Flagged} alt="Flagged" style={{ width: '20px', height: '20px' }} />}
          </TableCell>
        )}
        {visibleColumns.badgeSerialNumber && (
          <TableCell component="th" scope="row" align="left" onClick={(e) => gotToOfficerProfile(e, row, handleClick)}>
            {row?.badgeNumber}
          </TableCell>
        )}
        {visibleColumns.officerName && (
          <TableCell component="th" scope="row" align="left" onClick={(e) => gotToOfficerProfile(e, row, handleClick)}>
            {row?.firstName} {row?.lastName}
          </TableCell>
        )}
        {visibleColumns.officerPhone && (
          <TableCell component="th" scope="row" align="left" onClick={(e) => gotToOfficerProfile(e, row, handleClick)}>
            {row?.phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/g, '($1) $2-$3')}
          </TableCell>
        )}
        {visibleColumns.createdDate && (
          <TableCell component="th" scope="row" align="left">
            {moment(row?.createdAt).format('MMMM DD, YYYY')}
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <>
      {organization && <UpgradeModal open={!organization?.features?.dashboard?.officers?.enabled} />}
      <Box
        className={classes.container}
        style={
          !organization?.features?.dashboard?.officers?.enabled
            ? {
                filter: 'blur(6px)',
                height: '70vh',
                overflow: 'hidden',
              }
            : {}
        }
      >
        <Typography variant="h5">Officers</Typography>

        <TabsModules
          setTabs={setUserTabs}
          updateNameTab={updateNameTab}
          tabSelected={tabSelected}
          activeTabName={activeTabName}
          activeTab={activeTab}
          setTabSelected={setTabSelected}
          setActiveTab={setActiveTab}
          setActiveTabName={setActiveTabName}
          tabs={userTabs}
          navigateToDataPage={() => {}}
          saveTab={handleSaveTab}
          onTabSelect={handleTabSelection}
          nextPosition={nextPosition}
          saveTabPositions={handleTabsReorder}
          onTabAction={handleTabSelectionAction}
          deleteTab={deleteSelectedTab}
          moduleName="OFFICERS"
        />

        {activeTab === TYPE_USER_TABS.MAIN && (
          <ViewTabOfficers
            setLoading={setLoading}
            getOfficers={getOfficers}
            isFiltering={isFiltering}
            getVisibleColumns={getVisibleColumns}
            classes={classes}
            badge={badge}
            officers={officers}
            handleClick={handleClick}
            openPopover={openPopover}
            anchorEl={anchorEl}
            handleClose={handleClose}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            showColumns={showColumns}
            handleToggleColumn={handleToggleColumn}
            formatHeadersText={formatHeadersText}
            visibleColumns={visibleColumns}
            hideColumns={hideColumns}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            name={name}
            setPage={setPage}
            flaggedOnly={flaggedOnly}
            page={page}
            rowsPerPage={rowsPerPage}
            sort={sort}
            sortBy={sortBy}
            setIsFiltering={setIsFiltering}
            selected={selected}
            setOpen={setOpen}
            fetchFiltersConfig={fetchFiltersConfig}
            setSort={setSort}
            filtersLoaded={filtersLoaded}
            setFiltersLoaded={setFiltersLoaded}
            setSortBy={setSortBy}
            tabSelected={tabSelected}
            setVisibleColumns={setVisibleColumns}
            setOpenDelete={setOpenDelete}
            onEditMultipleOfficers={onEditMultipleOfficers}
            setOfficers={setOfficers}
            onClearAllFilters={onClearAllFilters}
            renderRow={renderRow}
            setChecked={setChecked}
            checked={checked}
            loading={loading}
            setSelected={setSelected}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}

        {activeTab === TYPE_USER_TABS.VIEW && (
          <ViewTabOfficers
            setLoading={setLoading}
            getOfficers={getOfficers}
            isFiltering={isFiltering}
            getVisibleColumns={getVisibleColumns}
            classes={classes}
            badge={badge}
            officers={officers}
            handleClick={handleClick}
            openPopover={openPopover}
            anchorEl={anchorEl}
            handleClose={handleClose}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            showColumns={showColumns}
            handleToggleColumn={handleToggleColumn}
            formatHeadersText={formatHeadersText}
            visibleColumns={visibleColumns}
            hideColumns={hideColumns}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            name={name}
            setPage={setPage}
            flaggedOnly={flaggedOnly}
            page={page}
            rowsPerPage={rowsPerPage}
            sort={sort}
            sortBy={sortBy}
            setIsFiltering={setIsFiltering}
            selected={selected}
            setOpen={setOpen}
            fetchFiltersConfig={fetchFiltersConfig}
            setSort={setSort}
            filtersLoaded={filtersLoaded}
            setFiltersLoaded={setFiltersLoaded}
            setSortBy={setSortBy}
            tabSelected={tabSelected}
            setVisibleColumns={setVisibleColumns}
            setOpenDelete={setOpenDelete}
            onEditMultipleOfficers={onEditMultipleOfficers}
            setOfficers={setOfficers}
            onClearAllFilters={onClearAllFilters}
            renderRow={renderRow}
            setChecked={setChecked}
            checked={checked}
            loading={loading}
            setSelected={setSelected}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}

        <Modal
          aria-labelledby="transition-modal-title2"
          aria-describedby="transition-modal-description2"
          className={classes.modal}
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={openDelete}>
            <div className={classes.paper}>
              <Typography style={{ fontSize: 20, fontWeight: '500' }}>Are you sure you want to delete?</Typography>
              <Typography variant="body2">This action can’t be undone!</Typography>
              <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
                <Button variant="outlined" className={classes.buttonOutlined} onClick={() => setOpenDelete(false)}>
                  CANCEL
                </Button>
                <Button variant="outlined" className={classes.buttonOutlined} onClick={onDeleteOfficer}>
                  {loadingDeleteOfficer ? <CircularProgress color="#fff" size="25px" /> : 'DELETE'}
                </Button>
              </Box>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title1"
          aria-describedby="transition-modal-description1"
          className={classes.modal}
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <Typography style={{ fontSize: 20, fontWeight: '500' }}>Create officer</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="standard-required1"
                    label={
                      <Typography>
                        {' '}
                        First Name <span style={{ color: 'red', fontSize: 12 }}>*</span>
                      </Typography>
                    }
                    style={{ width: '100%' }}
                    value={firstName.value}
                    onChange={firstName.onChange}
                    error={firstName.error}
                    helperText={firstName.error}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="standard-required1"
                    label={
                      <Typography>
                        {' '}
                        Last Name <span style={{ color: 'red', fontSize: 12 }}>*</span>
                      </Typography>
                    }
                    style={{ width: '100%' }}
                    value={lastName.value}
                    onChange={lastName.onChange}
                    error={lastName.error}
                    helperText={lastName.error}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="standard-required1"
                    label={<Typography> Email </Typography>}
                    style={{ width: '100%' }}
                    value={email.value}
                    onChange={email.onChange}
                    error={email.error}
                    helperText={email.error}
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* <Input
                  name="textmask"
                  id="formatted-text-mask-input"
                  label={
                    <Typography>
                      {' '}
                      Phone{' '}
                      <span style={{ color: 'red', fontSize: 12 }}>*</span>
                    </Typography>
                  }
                  inputProps={{ maxlength: 10 }}
                  style={{ width: '100%' }}
                  value={phone.value}
                  onChange={(e) => {
                    // if (e.target.value.length === 0) {
                    //   phone.setValue('');
                    // }
                    // if (e.target.value.match(/[0-9]$/g)) {
                    // }
                    console.log(e.target.value);
                    phone.onChange(e);
                  }}
                  error={phone.error}
                  helperText={phone.error}
                  inputComponent={TextMaskCustom}
                /> */}
                  {/* <Box style={{ display: 'flex', alignItems: 'flex-end' }}> */}
                  <InputLabel htmlFor="formatted-text-mask-input" style={{ fontSize: 13, marginBottom: 3 }}>
                    Phone Number{' '}
                  </InputLabel>
                  <Input
                    value={phone.value}
                    onChange={phone.onChange}
                    name="textmask"
                    style={{ width: '100%' }}
                    error={phone.error}
                    id="formatted-text-mask-input"
                    inputComponent={TextMaskCustom}
                  />
                  {/* </Box> */}
                  {phone.error && (
                    <span
                      style={{
                        fontSize: '0.75rem',
                        color: '#f44336',
                        marginTop: 3,
                      }}
                    >
                      {phone.error}
                    </span>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="standard-required1"
                    label={
                      <Typography>
                        {' '}
                        Badge/Serial Number <span style={{ color: 'red', fontSize: 12 }}>*</span>
                      </Typography>
                    }
                    style={{ width: '100%' }}
                    value={badgeNumber.value}
                    onChange={badgeNumber.onChange}
                    error={badgeNumber.error}
                    helperText={badgeNumber.error}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.label} style={{ marginTop: -3.5 }}>
                    Gender
                  </Typography>
                  <MultiSelectBox
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    id="discliplineMutliSelct1"
                    options={genderOptions || []}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.label}>
                    Race/Ethnicity
                  </Typography>
                  <MultiSelectBox
                    value={race}
                    onChange={(e) => setRace(e.target.value)}
                    id="discliplineMutliSelct1"
                    options={raceEthnicityOptions || []}
                  />
                </Grid>
              </Grid>
              <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
                <Button variant="outlined" className={classes.buttonOutlined} onClick={() => setOpen(false)}>
                  CANCEL
                </Button>
                <Button variant="outlined" className={classes.buttonOutlined} onClick={onCreateOfficer}>
                  {loadingCreateOfficer ? <CircularProgress color="#4b7bff" size="25px" /> : 'Create'}
                </Button>
              </Box>
            </div>
          </Fade>
        </Modal>
      </Box>
    </>
  );
};
export default OfficersList;
