import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Tooltip,
  Typography,
  Popover,
  IconButton,
  List,
  ListItem,
  Divider,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import PageButtons from '../../components/PageButtons';
import CaseFilters from '../../components/CaseFilters';
import EnhancedTable from '../../components/Table';
import Alert from '../../components/Alert';
import { getCivilianCases, getfiltersConfigById, getViewByIdv } from '../../services/unarmed';
import AddMore from '../../components/AddMore';
import { TYPE_USER_TABS } from '../../utils/constants';

export const ViewTabCases = ({
  classes,
  forms,
  handleClick,
  openPopover,
  anchorEl,
  handleClose,
  searchTerm,
  setSearchTerm,
  showColumns,
  handleToggleColumn,
  formatHeadersText,
  visibleColumns,
  hideColumns,
  openFilter,
  setOpenFilter,
  onEditMultipleForms,
  selected,
  setOpenModal,
  onGetFormRange,
  fetchFiltersConfig,
  setForms,
  onClearFilters,
  setPage,
  rowsPerPage,
  sort,
  sortBy,
  authUser,
  setFormType,
  formType,
  code,
  from,
  to,
  status,
  setFrom,
  setTo,
  name,
  assigned,
  setAssigned,
  source,
  reporter,
  investigator,
  allegation,
  disposition,
  discipline,
  setAllegation,
  setDisposition,
  setDiscipline,
  location,
  isFiltering,
  setIsFiltering,
  archivedOnly,
  transferredOnly,
  flaggedOnly,
  subType,
  isFilteringByContact,
  showAlert,
  onClearOfficersCases,
  setSort,
  getVisibleColumns,
  setSortBy,
  renderRow,
  setChecked,
  checked,
  loading,
  page,
  setSelected,
  handleChangeRowsPerPage,
  tabSelected,
  setVisibleColumns,
}) => {
  const [view, setView] = useState();
  const [filtersConfig, setfiltersConfig] = useState({});
  const [filtersApplied, setFiltersApplied] = useState({});
  const [filterCount, setFilterCount] = useState(0);

  const getFormsCivilians = async ({
    typeV,
    fromV,
    toV,
    codeV,
    statusV,
    nameV,
    assignedToV,
    sourceV,
    reporterV,
    investigatorV,
    allegationV,
    dispositionV,
    disciplineV,
    subTypeV,
  }) => {
    try {
      const { data, headers } = await getCivilianCases({
        token: authUser.tokenId,
        page,
        limit: rowsPerPage,
        sortBy,
        sort,
        isFiltering,
        type: typeV,
        from: fromV ? moment(fromV).format('YYYY-MM-DD') : null,
        to: toV ? moment(toV).format('YYYY-MM-DD') : null,
        code: codeV,
        status: statusV,
        name: nameV,
        assignedTo: assignedToV?._id || (assigned === 'null' ? assignedToV : location.state?.unassigned),
        source: sourceV || [],
        reporter: reporterV || [],
        investigator: investigatorV || [],
        allegation: allegationV || [],
        disposition: dispositionV || [],
        discipline: disciplineV || [],
        flaggedOnly: flaggedOnly.value,
        subType: subTypeV || [],
        contact: isFilteringByContact ? location?.state?.id : undefined,
        filtersConfigId: filtersConfig?._id,
        archivedOnly: filtersConfig?.archivedOnly,
        transferredOnly: filtersConfig?.transferredOnly,
      });

      if (filtersConfig?.archivedOnly) {
        archivedOnly.setValue(filtersConfig.archivedOnly || false);
      }
      if (filtersConfig?.transferredOnly) {
        transferredOnly.setValue(filtersConfig.transferredOnly || false);
      }

      setForms({ data, headers });
      fetchFiltersConfig();
    } catch (error) {
      console.log({ error });
    }
  };

  const handleOnSetFiltersConfig = () => {
    const newFiltersApplied = {};
    if (filtersConfig) {
      const configCopy = { ...filtersConfig };

      delete configCopy.sortOrder;
      delete configCopy.sortBy;
      delete configCopy.page;
      delete configCopy.limit;
      delete configCopy.isFiltering;
      Object.keys(configCopy).forEach((key) => {
        const value = configCopy[key];
        let isApplied = false;

        if (value === null || !value || value === '0' || value === '') {
          isApplied = false;
        } else if (Array.isArray(value)) {
          isApplied = value.length > 0;
        } else if (typeof value === 'boolean') {
          isApplied = value === true;
        } else if (typeof value === 'object' && value !== null) {
          isApplied = value.value !== undefined && value.value !== null && value.value !== '' && value.value !== false;
        } else {
          isApplied = true;
        }

        newFiltersApplied[key] = isApplied;
      });
      const newCount = Object.values(newFiltersApplied).filter((item) => item).length;
      setFilterCount(newCount);
      setFiltersApplied(newFiltersApplied);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        tabSelected?.displayObjectId &&
        (tabSelected?.displayObjectType === TYPE_USER_TABS.VIEW ||
          tabSelected?.displayObjectType === TYPE_USER_TABS.MAIN)
      ) {
        const responseView = await getViewByIdv(tabSelected?.displayObjectId);
        if (responseView?.data?.view[0]) {
          setView(responseView.data.view[0]);
          setVisibleColumns(responseView.data.view[0].columns);
        }
      }
    };

    fetchData();
  }, [tabSelected]);

  useEffect(() => {
    const fetchData = async () => {
      if (view) {
        const responsefiltersConfig = await getfiltersConfigById(view?.filtersConfigId);
        setfiltersConfig(responsefiltersConfig?.data?.filterConfig[0]?.config);
      }
    };

    fetchData();
  }, [view]);

  useEffect(() => {
    if (filtersConfig) {
      handleOnSetFiltersConfig();
      setFrom(filtersConfig.from);
      setTo(filtersConfig.to);
      setFormType(filtersConfig.type);
      flaggedOnly.setValue(filtersConfig.flaggedOnly?.value);
      code.setValue(filtersConfig.code);
      name.setValue(filtersConfig.name);
      setAssigned(filtersConfig.assignedTo);
      status.setValue(filtersConfig.status);
      transferredOnly.setValue(filtersConfig.transferredOnly || false);
      archivedOnly.setValue(filtersConfig.archivedOnly || false);
      subType.setValue(filtersConfig.subtype || []);
      source.setValue(filtersConfig.source || []);
      reporter.setValue(filtersConfig.reporter || []);
      setAllegation(filtersConfig.allegations || []);
      setDisposition(filtersConfig.dispositions || []);
      setDiscipline(filtersConfig.disciplines || []);
      investigator.setValue(filtersConfig.investigator || []);
      setIsFiltering(true);
      getFormsCivilians({
        typeV: filtersConfig.type,
        fromV: filtersConfig.from,
        toV: filtersConfig.to,
        codeV: filtersConfig.code,
        statusV: filtersConfig.status,
        nameV: filtersConfig.name,
        assignedToV: filtersConfig.assignedTo,
        sourceV: filtersConfig.source,
        reporterV: filtersConfig.reporter,
        investigatorV: filtersConfig.investigator,
        allegationV: filtersConfig.allegations,
        dispositionV: filtersConfig.dispositions,
        disciplineV: filtersConfig.disciplines,
        subTypeV: filtersConfig.subtype,
      });
    } else if (filtersConfig === null) {
      setIsFiltering(false);
      getCivilianCases();
    }
  }, [filtersConfig, page, sort, sortBy]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} marginTop="35px">
        <Box display="flex" alignItems="center">
          <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
            All Cases ({forms?.headers?.['x-pagination-totalitems']})
          </Typography>

          <AddMore text="" marginLeft="0" marginTop="0" onClick={handleClick} />
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Box p={2} width="240px" height="400px" style={{ overflow: 'auto' }}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h7" style={{ marginBottom: '2px', fontWeight: 'bold' }}>
                  Show / Hide Columns
                </Typography>
              </Box>

              <Divider style={{ marginTop: '2px', marginBottom: '2px' }} />

              <TextField
                placeholder="Search columns"
                variant="standard"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ fontSize: '12px' }} />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                  style: {
                    fontSize: '12px',
                    height: '28px',
                  },
                }}
                style={{
                  padding: '5px 0',
                  backgroundColor: 'transparent',
                  fontSize: '12px',
                  height: '28px',
                }}
              />

              <Divider style={{ marginTop: '7px', marginBottom: '5px' }} />

              <Box display="flex" justifyContent="center">
                <Typography variant="caption" style={{ marginBottom: '5px', fontSize: '14px', fontWeight: 'bold' }}>
                  Visible
                </Typography>
              </Box>

              <List dense style={{ padding: 0 }}>
                {showColumns.map((column) => (
                  <ListItem
                    key={column}
                    button
                    onClick={() => handleToggleColumn(view._id, column)}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '.1px',
                    }}
                  >
                    <Tooltip title={formatHeadersText(column)}>
                      <Typography variant="caption" style={{ lineHeight: '1.2' }}>
                        {formatHeadersText(column)}
                      </Typography>
                    </Tooltip>
                    <Checkbox
                      checked={visibleColumns[column]}
                      color="primary"
                      style={{ transform: 'scale(0.7)', paddingTop: '2px', paddingBottom: '2px' }}
                    />
                  </ListItem>
                ))}
              </List>

              <Divider style={{ margin: '10px 0' }} />

              <Box display="flex" justifyContent="center">
                <Typography
                  variant="caption"
                  style={{ marginBottom: '5px', marginTop: '5px', fontSize: '14px', fontWeight: 'bold' }}
                >
                  Hidden
                </Typography>
              </Box>

              <List dense style={{ padding: 0 }}>
                {hideColumns.map((column) => (
                  <ListItem
                    key={column}
                    button
                    onClick={() => handleToggleColumn(view._id, column)}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '.1px',
                    }}
                  >
                    <Tooltip title={formatHeadersText(column)}>
                      <Typography variant="caption" style={{ lineHeight: '1.2' }}>
                        {formatHeadersText(column)}
                      </Typography>
                    </Tooltip>
                    <Checkbox
                      checked={visibleColumns[column]}
                      color="primary"
                      style={{ transform: 'scale(0.7)', paddingTop: '2px', paddingBottom: '2px' }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Popover>
        </Box>
        <PageButtons
          text=""
          filterCount={filterCount}
          length={forms?.headers?.['x-pagination-totalitems']}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          onClickFlag={onEditMultipleForms}
          disabledFlag={selected.length === 0}
          onClickDelete={() => setOpenModal(true)}
          disabledDelete={selected.length === 0}
          onClickDownload={onGetFormRange}
          disabledDownload={selected.length === 0}
          filterButtonDataTestId="cases-button-open-filters"
          deleteButtonDataTestId="cases-button-delete-cases"
          downloadButtonDataTestId="cases-button-download"
        />
      </Box>

      {openFilter && (
        <CaseFilters
          setfiltersConfig={setfiltersConfig}
          filterConfigId={view?.filtersConfigId}
          filtersApplied={filtersApplied}
          fetchFiltersConfig={fetchFiltersConfig}
          setForms={setForms}
          onClearFilters={onClearFilters}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          sort={sort}
          sortBy={sortBy}
          tokenId={authUser?.tokenId}
          onClick={() => setOpenFilter(false)}
          setFormType={setFormType}
          formType={formType}
          code={code}
          from={from}
          to={to}
          status={status}
          setFrom={setFrom}
          setTo={setTo}
          name={name}
          assigned={assigned}
          setAssigned={setAssigned}
          source={source}
          reporter={reporter}
          investigator={investigator}
          allegation={allegation}
          disposition={disposition}
          discipline={discipline}
          setAllegation={setAllegation}
          setDisposition={setDisposition}
          setDiscipline={setDiscipline}
          badgeNumber={location?.state?.badgeNumber || null}
          isFiltering={isFiltering}
          setIsFiltering={setIsFiltering}
          archivedOnly={archivedOnly}
          transferredOnly={transferredOnly}
          flaggedOnly={flaggedOnly}
          subType={subType}
          isFilteringByContact={isFilteringByContact}
          contactId={location?.state?.id}
          filtersConfig={filtersConfig}
        />
      )}

      {showAlert && (
        <Alert onClick={onClearOfficersCases}>
          <Typography style={{ fontSize: 14 }}>
            {isFilteringByContact ? (
              <>
                You are only seeing cases from Contact: <strong>{location?.state?.name} </strong>email:{' '}
                <strong>{location?.state?.email}</strong>
              </>
            ) : (
              <>
                You are only seeing cases from Officer: <strong>{location?.state?.name} </strong>Badge/Serial Number:{' '}
                <strong>{location?.state?.badgeNumber}</strong>
              </>
            )}
          </Typography>
        </Alert>
      )}

      <Box marginBottom="0">
        <EnhancedTable
          sort={sort}
          setSort={setSort}
          columns={getVisibleColumns()}
          rows={forms.data}
          showCheckBox
          setSortBy={setSortBy}
          rowRender={renderRow}
          setChecked={setChecked}
          checked={checked}
          loading={loading}
          page={page}
          setPage={setPage}
          selected={selected}
          setSelected={setSelected}
          count={forms?.headers?.['x-pagination-totalitems']}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 15, 20, 50, 100]}
        />
      </Box>
    </>
  );
};
